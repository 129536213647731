import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Alert,
  Typography
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import confetti from "canvas-confetti";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";

import bitcoinIcon from "../../assets/images/bitcoin.png";
import litecoinIcon from "../../assets/images/litecoin.png";
import solanaIcon from "../../assets/images/solana.png";
import ethereumIcon from "../../assets/images/ethereum.png";
import paypalIcon from "../../assets/images/paypal.png";
import cashappIcon from "../../assets/images/cashapp.png";

const Payment = () => {
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [redirectCountdown, setRedirectCountdown] = useState(3);
  const [redirected, setRedirected] = useState(false);

  const breadcrumbItem = [
    { name: "Deposit" }
  ];

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setAmount(value);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      colors: ["#ffcc00", "#ff5733", "#33ccff", "#ff33ff", "#33ff57"],
    });
  };

  const handlePayment = async () => {
    if (!amount || parseFloat(amount) < 10) {
      setNotification({ message: "Amount must be at least $10.", type: "error" });
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.PUBLIC_URL || "http://localhost:3000"}/api/create-deposit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            amount: parseFloat(amount),
            payment_method: paymentMethod,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setNotification({
          message: "Invoice created successfully!",
          type: "success",
        });
        setPaymentDetails(result);
        triggerConfetti();

        if (["cashapp", "paypal"].includes(paymentMethod)) {
          let countdown = 3;
          const countdownInterval = setInterval(() => {
            setRedirectCountdown(countdown);
            countdown -= 1;
            if (countdown < 0) {
              clearInterval(countdownInterval);
              window.open(result.payment_link, "_blank");
              setRedirected(true);
            }
          }, 1000);
        }
      } else {
        setNotification({
          message: result.message || "Error creating invoice.",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Payment error:", error);
      setNotification({
        message: "An error occurred. Please try again.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getPaymentIcon = () => {
    switch (paymentMethod) {
      case "bitcoin":
        return bitcoinIcon;
      case "litecoin":
        return litecoinIcon;
      case "solana":
        return solanaIcon;
      case "ethereum":
        return ethereumIcon;
      case "paypal":
        return paypalIcon;
      case "cashapp":
        return cashappIcon;
      default:
        return null;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setNotification({
        message: "Copied to clipboard!",
        type: "success",
      });
      setTimeout(() => setNotification({ message: "", type: "" }), 2000);
    });
  };

  return (
    <><div style={{ marginTop: "15px", marginLeft: "35px" }}>

      <Breadcrumb breadcrumbItem={breadcrumbItem} />
      <WelcomeHeader />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "20px",
          backgroundColor: "var(--body-color)",
          color: "var(--font-color)",
          minHeight: "100vh",
        }}
      >
        <div
          className="card bg-card-color rounded-xl md:p-6 p-4 border border-dashed border-border-color"
          style={{
            maxWidth: "600px",
            width: "100%",
            backgroundColor: "var(--card-color)",
            marginTop: "10px",
          }}
        >
          <TextField
            label="Amount"
            value={amount}
            onChange={handleAmountChange}
            fullWidth
            margin="normal"
            variant="outlined"
            placeholder="$0.00"
            inputProps={{ inputMode: "numeric" }}
            style={{
              backgroundColor: "#e0e0e0",
              color: "var(--font-color-100)",
              borderRadius: "8px",
              textAlign: "center",
            }}
          />
          <TextField
            label="Select Payment Method"
            select
            value={paymentMethod}
            onChange={handlePaymentMethodChange}
            fullWidth
            margin="normal"
            variant="outlined"
            style={{
              backgroundColor: "#e0e0e0",
              color: "var(--font-color-100)",
              borderRadius: "8px",
              textAlign: "center",
            }}
          >
            <MenuItem value="" disabled>Select Payment Method</MenuItem>
            {/* <MenuItem value="paypal">PayPal</MenuItem> */}
            <MenuItem value="cashapp">CashApp</MenuItem>
            <MenuItem value="bitcoin">Bitcoin</MenuItem>
            <MenuItem value="litecoin">Litecoin</MenuItem>
            <MenuItem value="solana">Solana</MenuItem>
            <MenuItem value="ethereum">Ethereum</MenuItem>
          </TextField>
          {paymentMethod && (
            <Button
              onClick={handlePayment}
              variant="contained"
              fullWidth
              disabled={isLoading}
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                backgroundColor: "#ffffff",
                color: "#000000",
                border: "1px solid #000000",
                fontWeight: "bold",
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  {getPaymentIcon() && (
                    <img
                      src={getPaymentIcon()}
                      alt={`${paymentMethod} icon`}
                      width="20"
                      height="20"
                    />
                  )}
                  Pay with {paymentMethod ? paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1) : ""}
                </>
              )}
            </Button>
          )}

          {notification.message && (
            <Alert
              severity={notification.type}
              style={{
                marginTop: "20px",
                textAlign: "center",
                color: "var(--font-color-200)",
              }}
            >
              {notification.message}
            </Alert>
          )}

          {paymentDetails && (
            <div className="p-4 mt-4 text-center border border-dashed border-border-color rounded-xl">
              <h3 className="text-gray mb-2">Payment Details</h3>
              <p className="text-font-color-100">
                Transaction ID: <strong>{paymentDetails.payment_id}</strong>
                <Button
                  size="small"
                  onClick={() => copyToClipboard(paymentDetails.payment_id)}
                  style={{ marginLeft: "8px", minWidth: "30px" }}
                >
                  <ContentCopy fontSize="small" />
                </Button>
              </p>
              <p className="text-font-color-100">
                Amount: <strong>${paymentDetails.amount}</strong> {paymentDetails.currency}
              </p>
              {["bitcoin", "litecoin", "solana", "ethereum"].includes(paymentMethod) ? (
                <>
                  <p>
                    Address: <strong>{paymentDetails.address}</strong>
                    <Button
                      size="small"
                      onClick={() => copyToClipboard(paymentDetails.address)}
                      style={{ marginLeft: "8px", minWidth: "30px" }}
                    >
                      <ContentCopy fontSize="small" />
                    </Button>
                  </p>
                  <p>
                    Charge: <strong>{paymentDetails.charge} {paymentDetails.crypto}</strong>
                    <Button
                      size="small"
                      onClick={() => copyToClipboard(paymentDetails.charge)}
                      style={{ marginLeft: "8px", minWidth: "30px" }}
                    >
                      <ContentCopy fontSize="small" />
                    </Button>
                  </p>
                  <img
                    src={paymentDetails.qr_code_url}
                    alt="QR Code"
                    style={{
                      marginTop: "20px",
                      width: "150px",
                      height: "150px",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    src={paymentDetails.qr_code_url}
                    alt="QR Code"
                    style={{
                      marginTop: "20px",
                      width: "150px",
                      height: "150px",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                  <p>
                    Amount: <strong>${paymentDetails.amount}</strong>
                  </p>
                  <p>
                    Currency: <strong>{paymentDetails.currency}</strong>
                  </p>
                  <p>
                    Note: <strong>{paymentDetails.payment_id}</strong>
                    <Button
                      size="small"
                      onClick={() => copyToClipboard(paymentDetails.payment_id)}
                      style={{ marginLeft: "8px", minWidth: "30px" }}
                    >
                      <ContentCopy fontSize="small" />
                    </Button>
                  </p>
                  {redirected ? (
                    <p style={{ marginTop: "10px", color: "var(--font-color-400)" }}>
                      Go to the payment page to complete your payment.
                    </p>
                  ) : (
                    <p style={{ marginTop: "10px", color: "var(--font-color-400)" }}>
                      Redirecting to{" "}
                      <strong>{paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)}</strong>{" "}
                      in {redirectCountdown} seconds...
                    </p>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div
  style={{
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "var(--body-color)",
    border: "1px dashed var(--border-color)",
    borderRadius: "8px",
    textAlign: "center",
  }}
>
  <Typography
    variant="subtitle2"
    style={{
      fontWeight: "bold",
      color: "gray",
      marginBottom: "10px",
    }}
  >
    ⚠ Important Information:
  </Typography>
  <Typography
    variant="body2"
    style={{
      color: "var(--font-color)",
      lineHeight: "1.5",
    }}
  >
    Sending funds to any other wallet address is <strong>your responsibility</strong>. <br />
    Fake payments or incomplete transactions will not work. If you face issues, <br />
    <strong>join our Discord</strong> and create a ticket for support.
  </Typography>
</div>
      </div>

    </>
  );
};

export default Payment;
