import React from 'react'
import { Link } from 'react-router-dom'
import CompanyLogo from '../common/CompanyLogo'

export default function Footer({ className }) {

  const CurrentYear = new Date().getFullYear()

  return (
    <div className={`${className ? className : ''} footer md:p-6 sm:p-3 py-3 mt-auto`}>
    <div className='container-fluid flex items-center justify-center gap-15 md:flex-row flex-col md:text-[16px]/[24px] text-[14px]/[20px]'>
      <p className='text-font-color-100 text-center'>
        © {CurrentYear} <Link to="/" className='text-primary'>Hiddenlabel</Link>, All Rights Reserved.
      </p>
    </div>
  </div>
  
  )
}
