import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Modal,
  TextField,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faDownload, faTruck } from "@fortawesome/free-solid-svg-icons";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";

const TableStyled = styled(TableContainer)({
  marginTop: 20,
  borderRadius: 8,
  color: "#ffffff",
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
});

const ModalContainer = styled(Box)({
  backgroundColor: "#1c1e29",
  borderRadius: 10,
  padding: 20,
  color: "#fff",
  maxWidth: "600px",
  margin: "auto",
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
  textAlign: "center",
});

const PaginationStyled = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "gray",
  },
  "& .Mui-selected": {
    backgroundColor: "#4ecca3",
    color: "#fff",
  },
});

const CloseButton = styled(Button)({
  marginTop: 20,
  backgroundColor: "#fff",
  color: "#000",
  "&:hover": {
    backgroundColor: "#ddd",
  },
});

export default function BulkOrdersPage() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [searchParams, setSearchParams] = useState({
    transId: "",
    trackingId: "",
    createdAt: "",
  });
  const itemsPerPage = 10;
  const breadcrumbItem = [{ name: "Bulk Order History" }];

  useEffect(() => {
    loadOrders();
  }, [currentPage]);

  useEffect(() => {
    filterOrders();
  }, [searchParams, orders]);

  const loadOrders = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(
      `http://localhost:3000/api/bulk-orders?page=${currentPage}&limit=${itemsPerPage}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (result.message === "Bulk orders fetched successfully") {
      setOrders(result.orders);
      setTotalPages(result.totalPages);
      setFilteredOrders(result.orders);
    }
    setLoading(false);
  };

  const filterOrders = () => {
    const filtered = orders.filter((order) => {
      return (
        (!searchParams.transId ||
          order.trans_ids.some((id) =>
            id.toString().includes(searchParams.transId)
          )) &&
        (!searchParams.trackingId ||
          order.track_ids.some((id) => id.includes(searchParams.trackingId))) &&
        (!searchParams.createdAt ||
          order.created_at.startsWith(searchParams.createdAt))
      );
    });
    setFilteredOrders(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    setCurrentPage(1);
  };

  const viewOrder = (order) => {
    setOrderDetails(order);
    setOpenModal(true);
  };

  const downloadFile = async (transId, trackId, createdAt) => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    if (new Date(createdAt) < oneWeekAgo)
      return alert("Cannot download files older than one week.");

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        "http://localhost:3000/api/bulk-order-files",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ bulk_order_id: transId }),
        }
      );

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${trackId}.zip`;
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const renderOrderDetails = (details) => (
    <ModalContainer>
      <Typography
        variant="h6"
        sx={{ color: "#fff", marginBottom: 2, fontWeight: "bold" }}
      >
        Bulk Order Details
      </Typography>
      <Box sx={{ textAlign: "left", color: "gray" }}>
        <Typography>
          <strong>Service Code:</strong> {details.service_code}
        </Typography>
        <Typography>
          <strong>Price:</strong> ${details.price}
        </Typography>
        <Typography>
          <strong>Quantity:</strong> {details.quantity}
        </Typography>
        <Typography>
          <strong>Transaction IDs:</strong> {details.trans_ids.join(", ")}
        </Typography>
        <Typography>
          <strong>Tracking IDs:</strong> {details.track_ids.join(", ")}
        </Typography>
        <Typography>
          <strong>Created At:</strong> {new Date(details.created_at).toLocaleString()}
        </Typography>
      </Box>
      <CloseButton onClick={() => setOpenModal(false)}>Close</CloseButton>
    </ModalContainer>
  );

  return (
    <>
      <div style={{ marginTop: "15px", marginLeft: "35px" }}>
        <Breadcrumb breadcrumbItem={breadcrumbItem} />
        <WelcomeHeader />
      </div>
      <Box sx={{ padding: 3 }}>
        <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
          <TextField
            label="Transaction ID"
            variant="outlined"
            name="transId"
            value={searchParams.transId}
            onChange={handleSearchChange}
            fullWidth
            InputLabelProps={{ style: { color: "gray" } }}
            sx={{ input: { color: "gray" }, label: { color: "gray" } }}
          />
          <TextField
            label="Tracking ID"
            variant="outlined"
            name="trackingId"
            value={searchParams.trackingId}
            onChange={handleSearchChange}
            fullWidth
            InputLabelProps={{ style: { color: "gray" } }}
            sx={{ input: { color: "gray" }, label: { color: "gray" } }}
          />
          <TextField
            label="Created At"
            type="date"
            variant="outlined"
            name="createdAt"
            value={searchParams.createdAt}
            onChange={handleSearchChange}
            fullWidth
            InputLabelProps={{ shrink: true, style: { color: "gray" } }}
            sx={{ input: { color: "gray" }, label: { color: "gray" } }}
          />
        </Box>
        <TableStyled component={Paper}>
          <Table aria-label="Bulk Order Table" style={{ border: "2px dotted var(--border-color)", backgroundColor: "var(--card-color)" }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Order ID</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Service Code</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Price</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Quantity</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Created At</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Transaction IDs</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Tracking IDs</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>Actions</Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from(new Array(itemsPerPage)).map((_, index) => (
                    <TableRow key={index}>
                      {Array.from(new Array(8)).map((__, i) => (
                        <TableCell key={i}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : filteredOrders.map((order) => {
                    const createdAt = new Date(order.created_at);
                    const isDownloadAllowed = createdAt >= new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
                    return (
                      <TableRow key={order.id}>
                        <TableCell style={{ color: "#b0b0b0" }}>{order.id}</TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>{order.service_code}</TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>${order.price}</TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>{order.quantity}</TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>{createdAt.toLocaleDateString()}</TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>{order.trans_ids.join(", ")}</TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>{order.track_ids.join(", ")}</TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                            <Tooltip title="View Order Details">
                              <Button onClick={() => viewOrder(order)} color="primary">
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Download Label Zip">
                              <Button
                                onClick={() => downloadFile(order.id, order.track_ids[0], order.created_at)}
                                color="primary"
                                disabled={!isDownloadAllowed}
                              >
                                <FontAwesomeIcon icon={faDownload} />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Track Package">
                              <Button
                                href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${order.track_ids[0]}`}
                                target="_blank"
                                color="primary"
                              >
                                <FontAwesomeIcon icon={faTruck} />
                              </Button>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableStyled>
        <PaginationStyled
          count={totalPages}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
          sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
        />
        {orderDetails && (
          <Modal open={openModal} onClose={() => setOpenModal(false)}>
            {renderOrderDetails(orderDetails)}
          </Modal>
        )}
      </Box>
    </>
  );
}
