import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Alert,
  Tooltip,
  Button,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import bitcoinIcon from "../../assets/images/bitcoin.png";
import litecoinIcon from "../../assets/images/litecoin.png";
import solanaIcon from "../../assets/images/solana.png";
import ethereumIcon from "../../assets/images/ethereum.png";
import paypalIcon from "../../assets/images/paypal.png";
import cashappIcon from "../../assets/images/cashapp.png";

const TableStyled = styled(TableContainer)({
  marginTop: 20,
  borderRadius: 8,
  color: "#ffffff",
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
});

const PaginationStyled = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "gray",
  },
  "& .Mui-selected": {
    backgroundColor: "#4ecca3",
    color: "#fff",
  },
});



const paymentMethodsImages = {
    paypal: paypalIcon,
    cashapp: cashappIcon,
    bitcoin: bitcoinIcon,
    ethereum: ethereumIcon,
    litecoin: litecoinIcon,
    solana: solanaIcon,
  };
  

const statusClasses = {
  0: <span style={{ color: "orange" }}>Pending</span>,
  1: <span style={{ color: "green" }}>Paid</span>,
  2: <span style={{ color: "red" }}>Voided</span>,
};

const isAddedIcon = {
  0: <span style={{ color: "red" }}>✖</span>,
  1: <span style={{ color: "green" }}>✔</span>,
  2: <span style={{ color: "red" }}>✖</span>,
};

const ManageDeposits = () => {
  const [deposits, setDeposits] = useState([]);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const [notification, setNotification] = useState({ message: "", error: false });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const pageSize = 10; 
  const token = localStorage.getItem("token");

  const fetchDeposits = async (paymentId = "") => {
    let allDeposits = [];
    let page = 1;
    try {
      while (true) {
        const response = await fetch(
          `http://localhost:3000/api/admin/deposits?page=${page}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (data.message === "Deposits fetched successfully") {
          allDeposits = [...allDeposits, ...data.deposits];
          if (page >= data.totalPages) break;
          page++;
        } else {
          throw new Error("Failed to fetch deposits");
        }
      }
      const filtered = allDeposits.filter((deposit) =>
        deposit.payment_id.includes(paymentId)
      );
      setDeposits(allDeposits);
      setFilteredDeposits(filtered);
    } catch (error) {
      setNotification({ message: "Error fetching deposits", error: true });
    }
  };

  useEffect(() => {
    fetchDeposits();
  }, []);

  const handleAccept = async (depositId) => {
    try {
      const response = await fetch(
        `http://localhost:3000/api/admin/update-user-bal`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ order_id: depositId }),
        }
      );
      const data = await response.json();
      setNotification({
        message: data.message,
        error: data.message !== "User balance updated successfully",
      });
      fetchDeposits(searchQuery);
    } catch (error) {
      setNotification({ message: "Error updating deposit", error: true });
    }
  };

  const renderTableRows = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const depositsToDisplay = filteredDeposits.slice(startIndex, endIndex);

    return depositsToDisplay.map((deposit) => (
      <TableRow key={deposit.id}>
        <TableCell style={{ color: "#b0b0b0" }}>{deposit.id}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{deposit.user_id}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>
  <img
    src={paymentMethodsImages[deposit.payment_method]}
    alt={deposit.payment_method}
    width="24"
  />
</TableCell>

        <TableCell style={{ color: "#b0b0b0" }}>{deposit.amount}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{deposit.payment_id}</TableCell>
        <TableCell>
          <Box
            sx={{
              display: "inline-block",
              py: "2px",
              px: "8px",
              borderRadius: "4px",
              backgroundColor:
                deposit.status === 0
                  ? "#ffc107"
                  : deposit.status === 1
                  ? "#28a745" 
                  : "#dc3545",
              color: "#fff",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {deposit.status === 0
              ? "Pending"
              : deposit.status === 1
              ? "Paid"
              : "Voided"}
          </Box>
        </TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{isAddedIcon[deposit.is_added]}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{new Date(deposit.created_at).toLocaleString()}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>
          <Tooltip title="Accept Deposit">
            <Button
              color="success"
              onClick={() => handleAccept(deposit.id)}
            >
              ✔
            </Button>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = deposits.filter((deposit) =>
      deposit.payment_id.includes(query)
    );
    setFilteredDeposits(filtered);
    setCurrentPage(1); 
  };

  return (
    <>
    <div style={{ marginTop: "15px", marginLeft: "35px" }}>
   <Breadcrumb breadcrumbItem={[{ name: "Manage" }, { name: "User" }, { name: "Pricing" }]} />
     <WelcomeHeader />
     </div>
    <Box sx={{ padding: 3 }}>
      {notification.message && (
        <Alert
          severity={notification.error ? "error" : "success"}
          sx={{ mb: 2 }}
          onClose={() => setNotification({ message: "", error: false })}
        >
          {notification.message}
        </Alert>
      )}



<Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <TextField
           placeholder="Search by Payment ID..."
           value={searchQuery}
           onChange={(e) => handleSearch(e.target.value)}
          variant="outlined"
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "gray",
                borderStyle: "dotted",
              },
              "&:hover fieldset": {
                borderColor: "lightgray",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4ecca3",
              },
              color: "gray",
            },
            "& .MuiInputBase-input": {
              color: "gray",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "gray",
            },
          }}
        />
      </Box>


      <TableStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>ID</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>User ID</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Payment Method</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Amount</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Payment ID</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Is Added</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Created At</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableStyled>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <PaginationStyled
          count={Math.ceil(filteredDeposits.length / pageSize)}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
        />
      </Box>
    </Box>
    </> 
  );
};

export default ManageDeposits;
