import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Modal,
  Tooltip,
  Skeleton,
  TextField,
  MenuItem
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faDownload, faTruck } from "@fortawesome/free-solid-svg-icons";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";

const TableStyled = styled(TableContainer)({
  marginTop: 20,
  borderRadius: 8,
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
  color: "#ffffff",
});

const ModalContainer = styled(Box)({
  backgroundColor: "#1c1e29",
  borderRadius: 10,
  padding: 20,
  color: "#fff",
  maxWidth: "600px",
  margin: "auto",
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
  textAlign: "center",
});

const CloseButton = styled(Button)({
  marginTop: 20,
  backgroundColor: "#fff",
  color: "#000",
  "&:hover": {
    backgroundColor: "#ddd",
  },
});

const PaginationStyled = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "gray",
  },
  "& .Mui-selected": {
    backgroundColor: "#4ecca3",
    color: "#fff",
  },
});

const Filters = styled(Box)({
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)", 
    gap: "10px",
    marginBottom: "20px",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr",
    },
  });
  
  const FilterInput = styled(TextField)({
    flex: "1 1 calc(25% - 10px)",
    minWidth: "150px",
    "& .MuiOutlinedInput-root": {
      borderColor: "gray",
      color: "gray",
    },
    "& .MuiInputLabel-root": {
      color: "gray",
    },
    "& .MuiOutlinedInput-root:hover fieldset": {
      borderColor: "lightgray",
    },
    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
      borderColor: "white",
    },
    "@media (max-width: 768px)": {
      flex: "1 1 100%",
    },
  });
  

export default function ManifestPage() {
  const [manifest, setManifest] = useState([]);
  const [filteredManifest, setFilteredManifest] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchFilters, setSearchFilters] = useState({
    trackingId: "",
    fromName: "",
    toName: "",
    createdAt: "",
  });

  const itemsPerPage = 10;

  useEffect(() => {
    loadManifest();
  }, []);

  useEffect(() => {
    applySearchFilters();
  }, [searchFilters, manifest]);

  const loadManifest = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch("http://localhost:3000/api/get-manifest", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const result = await response.json();
      if (result.message === "Records fetched successfully") {
        setManifest(result.data);
        setFilteredManifest(result.data);
        setTotalPages(Math.ceil(result.data.length / itemsPerPage));
      }
    } catch (error) {
      console.error("Error fetching manifest:", error);
    } finally {
      setLoading(false);
    }
  };

  const applySearchFilters = () => {
    const filtered = manifest.filter((order) => {
      const matchesTrackingId = searchFilters.trackingId
        ? order.tracking_id
            ?.toLowerCase()
            .includes(searchFilters.trackingId.toLowerCase())
        : true;
      const matchesFromName = searchFilters.fromName
        ? order.order_info.FromName?.toLowerCase().includes(
            searchFilters.fromName.toLowerCase()
          )
        : true;
      const matchesToName = searchFilters.toName
        ? order.order_info.ToName?.toLowerCase().includes(
            searchFilters.toName.toLowerCase()
          )
        : true;
      const matchesCreatedAt = searchFilters.createdAt
        ? new Date(order.created_at).toLocaleDateString("en-US") ===
          new Date(searchFilters.createdAt).toLocaleDateString("en-US")
        : true;
      const matchesType = searchFilters.type
        ? order.type === searchFilters.type
        : true;
      const matchesStatus =
        searchFilters.status === "completed"
          ? !!order.tracking_id
          : searchFilters.status === "pending"
          ? !order.tracking_id
          : true;

      return (
        matchesTrackingId &&
        matchesFromName &&
        matchesToName &&
        matchesCreatedAt &&
        matchesType &&
        matchesStatus
      );
    });

    setFilteredManifest(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const viewOrder = (order) => {
    setSelectedOrder(order);
    setOpenModal(true);
  };

  const downloadManifest = async (trackingId) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        alert("You are not authenticated. Please log in and try again.");
        return;
      }

      const response = await fetch(
        `http://localhost:3000/api/download-manifest/${trackingId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        alert("Failed to download the file. Please try again.");
        return;
      }

      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${trackingId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading manifest:", error);
      alert("An error occurred while downloading the file.");
    }
  };

  const trackPackage = (trackingId) => {
    if (!trackingId) return alert("No tracking ID available for this order.");
    window.open(
      `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingId}`,
      "_blank"
    );
  };

  const renderOrderDetails = (order) => (
    <ModalContainer>
      <Typography
        variant="h6"
        sx={{ color: "#fff", marginBottom: 2, fontWeight: "bold" }}
      >
        Order Details
      </Typography>
      <Box sx={{ textAlign: "left", color: "gray" }}>
        <Typography>
          <strong>From:</strong> {order.order_info.FromName}
        </Typography>
        <Typography>
          <strong>To:</strong> {order.order_info.ToName}
        </Typography>
        <Typography>
          <strong>Price:</strong> ${order.price}
        </Typography>
        <Typography>
          <strong>Type:</strong> {order.type}
        </Typography>
        <Typography>
          <strong>Weight:</strong> {order.order_info.weight}
        </Typography>
        <Typography>
          <strong>From Address:</strong> {order.order_info.FromStreet},{" "}
          {order.order_info.FromCity}, {order.order_info.FromState},{" "}
          {order.order_info.FromZip}
        </Typography>
        <Typography>
          <strong>To Address:</strong> {order.order_info.ToStreet},{" "}
          {order.order_info.ToCity}, {order.order_info.ToState},{" "}
          {order.order_info.ToZip}
        </Typography>
      </Box>
      <CloseButton onClick={() => setOpenModal(false)}>Close</CloseButton>
    </ModalContainer>
  );

  const breadcrumbItem = [{ name: "Manifest History" }];

  return (
    <>
    
    <div style={{ marginTop: "15px", marginLeft: "35px" }}>
        <Breadcrumb breadcrumbItem={breadcrumbItem} />
        <WelcomeHeader />
      </div>
    <Box sx={{ padding: 3 }}>
     <Filters>
  <FilterInput
    label="From Name"
    variant="outlined"
    size="small"
    name="fromName"
    value={searchFilters.fromName}
    onChange={handleSearchChange}
    sx={{ backgroundColor: "card-body", color: "#fff" }}
    />
  <FilterInput
    label="To Name"
    variant="outlined"
    size="small"
    name="toName"
    value={searchFilters.toName}
    onChange={handleSearchChange}
    sx={{ backgroundColor: "card-body", color: "#fff" }}
    />
  <FilterInput
    label="Tracking ID"
    variant="outlined"
    size="small"
    name="trackingId"
    value={searchFilters.trackingId}
    onChange={handleSearchChange}
    sx={{ backgroundColor: "card-body", color: "#fff" }}
    />
  <FilterInput
    label="Created At"
    type="date"
    InputLabelProps={{ shrink: true }}
    variant="outlined"
    size="small"
    name="createdAt"
    value={searchFilters.createdAt}
    onChange={handleSearchChange}
    sx={{ backgroundColor: "card-body", color: "#fff" }}
    />
  <TextField
    label="Type"
    select
    variant="outlined"
    size="small"
    name="type"
    value={searchFilters.type}
    onChange={handleSearchChange}
    sx={{ backgroundColor: "card-body", color: "#fff", gridColumn: "span 2" }} 
    >
    <MenuItem value="">All</MenuItem>
    <MenuItem value="usps_ground">USPS Ground</MenuItem>
    <MenuItem value="usps_priority">USPS Priority</MenuItem>
  </TextField>
  <TextField
    label="Status"
    select
    variant="outlined"
    size="small"
    name="status"
    value={searchFilters.status}
    onChange={handleSearchChange}
    sx={{ backgroundColor: "card-body", color: "#fff", gridColumn: "span 2" }}
    >
    <MenuItem value="">All</MenuItem>
    <MenuItem value="completed">Completed</MenuItem>
    <MenuItem value="pending">Pending</MenuItem>
  </TextField>
</Filters>

      <TableStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Order ID
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                From
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                To
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Type
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Tracking ID
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Price
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Status
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Created at
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from(new Array(itemsPerPage)).map((_, index) => (
                  <TableRow key={index}>
                    {Array.from(new Array(9)).map((__, i) => (
                        <TableCell key={i}>
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
                : filteredManifest
                .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                )
                .map((order) => (
                    <TableRow key={order.id}>
                      <TableCell style={{ color: "#b0b0b0" }}>
                        {order.id}
                      </TableCell>
                      <TableCell style={{ color: "#b0b0b0" }}>
                        {order.order_info.FromName}
                      </TableCell>
                      <TableCell style={{ color: "#b0b0b0" }}>
                        {order.order_info.ToName}
                      </TableCell>
                      <TableCell style={{ color: "#b0b0b0" }}>
                        {order.type === "usps_ground"
                          ? "USPS GROUND"
                          : order.type === "usps_priority"
                          ? "USPS Manifested V2"
                          : order.type}
                      </TableCell>
                      <TableCell style={{ color: "#b0b0b0" }}>
                        {order.tracking_id}
                      </TableCell>
                      <TableCell style={{ color: "#b0b0b0" }}>
                        ${order.price}
                      </TableCell>
                      <TableCell>
                        {order.tracking_id ? (
                            <span
                            style={{
                                backgroundColor: "green",
                                color: "white",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontWeight: "bold",
                            }}
                            >
                            Completed
                          </span>
                        ) : (
                            <span
                            style={{
                                backgroundColor: "orange",
                                color: "white",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontWeight: "bold",
                            }}
                            >
                            Pending
                          </span>
                        )}
                      </TableCell>
                      <TableCell style={{ color: "#b0b0b0" }}>
                        {new Date(order.created_at).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        })}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View Order Details">
                          <Button
                            onClick={() => viewOrder(order)}
                            color="primary"
                            >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title={
                              order.tracking_id
                              ? "Download Manifest"
                              : "Tracking ID not available"
                            }
                            >
                          <Button
                            onClick={() =>
                                order.tracking_id &&
                                downloadManifest(order.tracking_id)
                            }
                            color="primary"
                            disabled={!order.tracking_id}
                            >
                            <FontAwesomeIcon icon={faDownload} />
                          </Button>
                        </Tooltip>

                        <Tooltip
                          title={
                              order.tracking_id
                              ? "Track Package"
                              : "Tracking ID not available"
                            }
                            >
                          <Button
                            onClick={() =>
                                order.tracking_id &&
                                trackPackage(order.tracking_id)
                            }
                            color="primary"
                            disabled={!order.tracking_id}
                            >
                            <FontAwesomeIcon icon={faTruck} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
      </TableStyled>
      <PaginationStyled
        count={totalPages}
        page={currentPage}
        onChange={(e, page) => setCurrentPage(page)}
        sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
        />
      {selectedOrder && (
          <Modal open={openModal} onClose={() => setOpenModal(false)}>
          {renderOrderDetails(selectedOrder)}
        </Modal>
      )}
    </Box>
      </>
  );
}
