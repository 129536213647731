import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {
  Box
} from "@mui/material";

const ModalContainer = styled(Box)({
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
  borderRadius: 10,
  padding: 20,
  color: "#b0b0b0",
  maxWidth: "600px",
  margin: "auto",
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
  textAlign: "center",
});

export default function Signup() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    joining_code: "",
  });
  const [alert, setAlert] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setAlert(
        <Alert severity="error" onClose={() => setAlert(null)}>
          <AlertTitle>Error</AlertTitle>
          Passwords do not match.
        </Alert>
      );
      return;
    }

    setAlert(null);

    try {
      const response = await fetch(
        `${process.env.PUBLIC_URL || "http://localhost:3000"}/api/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
          },
          body: JSON.stringify({
            email: formData.email,
            password: formData.password,
            joining_code: formData.joining_code,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setAlert(
          <Alert severity="success" onClose={() => setAlert(null)}>
            <AlertTitle>Success</AlertTitle>
            Account has been created successfully!
          </Alert>
        );

        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        setAlert(
          <Alert severity="error" onClose={() => setAlert(null)}>
            <AlertTitle>Error</AlertTitle>
            {data.message || "Failed to create account. Please try again."}
          </Alert>
        );
      }
    } catch (error) {
      console.error("Registration error:", error);
      setAlert(
        <Alert severity="error" onClose={() => setAlert(null)}>
          <AlertTitle>Error</AlertTitle>
          An error occurred. Please try again later.
        </Alert>
      );
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="sm:mb-8 mb-6 text-center">
        <div className="sm:text-[40px]/[48px] text-[30px]/[36px] font-medium mb-2">
          Create Account
        </div>
        <span className="text-font-color-100 inline-block">
          Free access to our dashboard.
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-control mb-15">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="name@example.com"
            className="form-input"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-control mb-15">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            placeholder="8+ characters required"
            className="form-input"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-control mb-15">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            placeholder="8+ characters required"
            className="form-input"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-control mb-15">
          <label htmlFor="joining_code" className="form-label">
            Joining Code
          </label>
          <input
            type="text"
            id="joining_code"
            placeholder="Joining code"
            className="form-input"
            value={formData.joining_code}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-check mb-30 flex items-center justify-center">
          <input
            type="checkbox"
            id="terms"
            className="form-check-input mr-2"
            required
          />
          <label className="form-check-label" htmlFor="terms">
            I accept the{" "}
            <span
              className="text-primary cursor-pointer"
              onClick={openModal}
            >
              Terms and Conditions
            </span>
          </label>
        </div>

        <button
          type="submit"
          className="btn btn-secondary large w-full uppercase"
        >
          Sign Up
        </button>

        <div className="text-center sm:mt-30 mt-6 text-font-color-100">
          <p>Already have an account</p>
          <Link to="/login" className="text-primary">
            Sign In
          </Link>
        </div>
      </form>

      {/* Modal */}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ModalContainer>
          <Typography id="modal-title" variant="h6" component="h2" mb={2}>
            Terms and Conditions
          </Typography>
          <Typography id="modal-description" sx={{ mb: 2 }}>
            1. You agree to use this service responsibly.<br />
            2. Do not share your login details with others.<br />
            3. Follow all community guidelines.<br />
            4. Any misuse of the service may result in account termination.<br />
          </Typography>
          <Button onClick={closeModal} variant="contained" color="primary">
            Close
          </Button>
        </ModalContainer>
      </Modal>
    </>
  );
}
