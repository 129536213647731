import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import {
    menuList,
} from './SidebarData';
import { IconPower, IconChevronRight, IconChevronsDown } from '@tabler/icons-react';

export default function Sidebar({ setMobileNav, note, toggleNote, chat, toggleChat }) {

    const pageUrl = useLocation().pathname;

    const [adminMenu, setAdminMenu] = useState(false)
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const toggleAdminMenu = () => {
        setAdminMenu(!adminMenu)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current && 
                !menuRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setAdminMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    const [menuActive, setMenuActive] = useState(0)
    const menuToggle = (key) => {
        setMenuActive(menuActive === key ? null : key)
    }

    const [menuActiveSub, setMenuActiveSub] = useState(false)
    const menuToggleSub = (key) => {
        setMenuActiveSub(menuActiveSub === key ? null : key)
    }

    const [schedule, setSchedule] = useState(false)
    const toggleSchedule = () => {
        setSchedule(!schedule)
    }

    const [newProjectSidebar, setNewProjectSidebar] = useState(false)
    const toggleNewProject = () => {
        setNewProjectSidebar(!newProjectSidebar)
    }

    let data;
    if (pageUrl) {
        data = menuList;
    } else {
        data = menuList;
    }

    useEffect(() => {
        data.forEach((item, index) => {
            if (item.children) {
                item.children.forEach((child, subIndex) => {
                    if (child.url === pageUrl) {
                        setMenuActive(index);
                        setMenuActiveSub(subIndex);
                    } else if (child.children) {
                        child.children.forEach((subChild) => {
                            if (subChild.url === pageUrl) {
                                setMenuActive(index);
                                setMenuActiveSub(subIndex);
                            }
                        });
                    }
                });
            } else if (item.url === pageUrl) {
                setMenuActive(index);
            }
        });
    }, [pageUrl, data]);

    return (
        <>
            <div className='sidebar-header px-3 mb-6 flex items-center justify-between gap-2'>
                <h4 className='sidebar-title text-[24px]/[30px] font-medium mb-0'>
                    <span className='sm-txt'>Hi</span><span>dden Label</span>
                </h4>
            </div>
            <ul className='sidebar-list px-3 mb-4 main-menu'>
                {data.map((item, key) => (
                    item.children ? <li key={key} className='sidebar-listitem'>
                        <button
                            onClick={() => menuToggle(key)}
                            className={`sidebar-list-button flex items-center gap-10 w-full py-10 transition-all hover:text-secondary ${menuActive === key ? 'text-secondary' : ''}`}
                        >
                            <item.icon className='stroke-[1.5] w-[22px] h-[22px]' />
                            <span className='link'>{item.link}</span>
                            {menuActive === key ? <IconChevronsDown className="arrow-icon stroke-[1.5] w-[20px] h-[20px] ms-auto" /> : <IconChevronRight className="arrow-icon stroke-[1.5] w-[18px] h-[18px] ms-auto rtl:rotate-180" />}
                        </button>
                        <ul className={`sidebar-sublist ps-30 relative before:absolute before:h-full before:w-[1px] ltr:before:left-10 rtl:before:right-10 before:top-0 before:bg-secondary ${menuActive === key ? 'block' : 'hidden'}`}>
                            {item.children.map((res, key) => (
                                res.children ?
                                    <li key={key}>
                                        <button
                                            onClick={() => menuToggleSub(key)}
                                            className={`flex items-center gap-10 w-full py-2 text-[14px]/[20px] relative before:hidden before:absolute before:rounded-full before:h-[9px] before:w-[9px] ltr:before:left-[-24px] rtl:before:right-[-24px] before:top-[50%] before:translate-y-[-50%] before:bg-secondary hover:text-secondary hover:before:block transition-all ${menuActiveSub === key ? 'text-secondary before:!block' : ''}`}
                                        >
                                            <span>{res.link}</span>
                                            {menuActiveSub === key ? <IconChevronsDown className="stroke-[1.5] w-[20px] h-[20px] ms-auto" /> : <IconChevronRight className="stroke-[1.5] w-[18px] h-[18px] ms-auto rtl:rotate-180" />}
                                        </button>
                                        <ul className={`ps-30 relative before:absolute before:h-full before:w-[1px] ltr:before:left-10 rtl:before:right-10 before:top-0 before:bg-secondary ${menuActiveSub === key ? 'block' : 'hidden'}`}>
                                            {res.children.map((sub, key) => (
                                                <li key={key}>
                                                    <Link to={sub.url} onClick={() => { window.innerWidth < 1200 && setMobileNav(false) }} className={`py-1 text-[14px]/[20px] flex relative before:hidden before:absolute before:rounded-full before:h-[9px] before:w-[9px] ltr:before:left-[-24px] rtl:before:right-[-24px] before:top-[50%] before:translate-y-[-50%] before:bg-secondary hover:text-secondary hover:before:block transition-all ${pageUrl === sub.url ? 'text-secondary before:!block' : ''}`}>
                                                        {sub.link}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                    :
                                    <li key={key}>
                                        <Link to={res.url} onClick={() => { window.innerWidth < 1200 && setMobileNav(false) }} className={`py-1 text-[14px]/[20px] flex relative before:hidden before:absolute before:rounded-full before:h-[9px] before:w-[9px] ltr:before:left-[-24px] rtl:before:right-[-24px] before:top-[50%] before:translate-y-[-50%] before:bg-secondary hover:text-secondary hover:before:block transition-all ${pageUrl === res.url ? 'text-secondary before:!block' : ''}`}>
                                            {res.link}
                                        </Link>
                                    </li>
                            ))}
                        </ul>
                    </li>
                        : item.url ?
                            <li key={key} className='sidebar-listitem'>
                                <Link to={item.url} onClick={() => { window.innerWidth < 1200 && setMobileNav(false) }} className={`sidebar-list-link flex items-center gap-10 w-full py-2 transition-all hover:text-secondary ${pageUrl === item.url ? 'text-secondary' : ''}`}>
                                    {item.icon ? <item.icon className='stroke-[1.5] w-[22px] h-[22px] rtl:rotate-180' /> : <IconChevronRight className='stroke-[1.5] w-[22px] h-[22px] rtl:rotate-180' />}
                                    <span className='link'>{item.link}</span>
                                </Link>
                            </li>
                            :
                            <li key={key} className={`devider py-3 menu-devider uppercase text-[12px]/[15px]${item.color ? ` text-${item.color}` : ''}${item.fontWeight ? ` font-${item.fontWeight}` : ''}`}>
                                {item.devider}
                            </li>
                ))}
            </ul>
            <div className='sidebar-bottom-link flex justify-evenly gap-3 mx-3 border border-dashed rounded-xl p-2 mt-auto'>
                <Link to="/logout" title='Log Out' className='transition-all duration-300 hover:text-secondary'>
                    <IconPower className='stroke-[1.5] w-[20px] h-[20px]' />
                </Link>
            </div>

        </>
    )
}
