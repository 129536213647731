import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Modal,
  TextField,
  CircularProgress,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faDownload, faTruck } from "@fortawesome/free-solid-svg-icons";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";

const TableStyled = styled(TableContainer)({
  marginTop: 20,
  borderRadius: 8,
  color: "#ffffff",
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
});

const ModalContainer = styled(Box)({
  backgroundColor: "#1c1e29",
  borderRadius: 10,
  padding: 20,
  color: "#fff",
  maxWidth: "600px",
  margin: "auto",
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
  textAlign: "center",
});

const PaginationStyled = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "gray",
  },
  "& .Mui-selected": {
    backgroundColor: "#4ecca3",
    color: "#fff",
  },
});

const CloseButton = styled(Button)({
  marginTop: 20,
  backgroundColor: "#fff",
  color: "#000",
  "&:hover": {
    backgroundColor: "#ddd",
  },
});

export default function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [searchParams, setSearchParams] = useState({
    from: "",
    to: "",
    trackingId: "",
    transId: "",
    date: "",
  });
  const itemsPerPage = 10;


  const breadcrumbItem = [{ name: "Order History" }];


  useEffect(() => {
    loadOrders();
  }, []);

  useEffect(() => {
    filterOrders();
  }, [searchParams, orders]);

  const loadOrders = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`http://localhost:3000/api/orders-for-users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (result.message === "Orders retrieved successfully") {
      setOrders(result.orders);
      setTotalPages(Math.ceil(result.orders.length / itemsPerPage));
      setFilteredOrders(result.orders);
    }
    setLoading(false);
  };

  const filterOrders = () => {
    const filtered = orders.filter((order) => {
      return (
        (!searchParams.from ||
          order.order_info.fromName
            .toLowerCase()
            .includes(searchParams.from.toLowerCase())) &&
        (!searchParams.to ||
          order.order_info.toName
            .toLowerCase()
            .includes(searchParams.to.toLowerCase())) &&
        (!searchParams.trackingId ||
          order.trackId.includes(searchParams.trackingId)) &&
        (!searchParams.transId ||
          order.transid.includes(searchParams.transId)) &&
        (!searchParams.date || order.created_at.startsWith(searchParams.date))
      );
    });
    setFilteredOrders(filtered);
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    setCurrentPage(1);
  };

  const viewOrder = async (orderId) => {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `http://localhost:3000/api/get-order-details`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_id: orderId,
        }),
      }
    );
    const result = await response.json();
    if (result.message === "Order details retrieved successfully") {
      setOrderDetails(result.orderDetails);
      setOpenModal(true);
    }
  };

  const downloadFile = async (transId, trackId, createdAt) => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    if (new Date(createdAt) < oneWeekAgo)
      return alert("Cannot download files older than one week.");

    try {
      const token = localStorage.getItem("token");
      const response = await fetch("http://localhost:3000/api/file-download", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          transId,
        }),
      });

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${trackId}.pdf`;
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const renderOrderDetails = (details) => (
    <ModalContainer>
      <Typography
        variant="h6"
        sx={{ color: "#fff", marginBottom: 2, fontWeight: "bold" }}>
        Order Details
      </Typography>

      <Box sx={{ textAlign: "left", color: "gray" }}>
        <Typography>
          <strong>Class:</strong> {details.order_info.class}
        </Typography>
        <Typography>
          <strong>Price:</strong> ${details.price}
        </Typography>
        <Typography>
          <strong>Transaction ID:</strong> {details.transid}
        </Typography>
        <Typography>
          <strong>Shipment Date:</strong>{" "}
          {new Date(details.order_info.shipmentDate).toLocaleString()}
        </Typography>
        <Typography>
          <strong>From:</strong> {details.order_info.fromName}
        </Typography>
        <Typography>
          <strong>From Address:</strong> {details.order_info.fromAddress}
        </Typography>
        <Typography>
          <strong>To:</strong> {details.order_info.toName}
        </Typography>
        <Typography>
          <strong>To Address:</strong> {details.order_info.toAddress}
        </Typography>
      </Box>
      <CloseButton onClick={() => setOpenModal(false)}>Close</CloseButton>
    </ModalContainer>
  );

  return (
    <>
    <div style={{ marginTop: "15px", marginLeft: "35px" }}>
      <Breadcrumb breadcrumbItem={breadcrumbItem} />
    <WelcomeHeader />
</div>
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
        <TextField
          label="From Name"
          variant="outlined"
          name="from"
          value={searchParams.from}
          onChange={handleSearchChange}
          fullWidth
          InputLabelProps={{ style: { color: "gray" } }}
          sx={{ input: { color: "gray" }, label: { color: "gray" } }}
          />
        <TextField
          label="To Name"
          variant="outlined"
          name="to"
          value={searchParams.to}
          onChange={handleSearchChange}
          fullWidth
          InputLabelProps={{ style: { color: "gray" } }}
          sx={{ input: { color: "gray" }, label: { color: "gray" } }}
          />
        <TextField
          label="Tracking ID"
          variant="outlined"
          name="trackingId"
          value={searchParams.trackingId}
          onChange={handleSearchChange}
          fullWidth
          InputLabelProps={{ style: { color: "gray" } }}
          sx={{ input: { color: "gray" }, label: { color: "gray" } }}
          />
        <TextField
          label="Transaction ID"
          variant="outlined"
          name="transId"
          value={searchParams.transId}
          onChange={handleSearchChange}
          fullWidth
          InputLabelProps={{ style: { color: "gray" } }}
          sx={{ input: { color: "gray" }, label: { color: "gray" } }}
          />
        <TextField
          label="Date"
          type="date"
          variant="outlined"
          name="date"
          value={searchParams.date}
          onChange={handleSearchChange}
          fullWidth
          InputLabelProps={{ shrink: true, style: { color: "gray" } }}
          sx={{ input: { color: "gray" }, label: { color: "gray" } }}
          />
      </Box>
      <TableStyled component={Paper}>
        <Table
          aria-label="CSV Table"
          style={{
              border: "2px dotted var(--border-color)",
              backgroundColor: "var(--card-color)",
            }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Order ID
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                From
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                To
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Class
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Price
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Transaction ID
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Tracking ID
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Date Created
              </TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from(new Array(itemsPerPage)).map((_, index) => (
                  <TableRow key={index}>
                    {Array.from(new Array(9)).map((__, i) => (
                        <TableCell key={i}>
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
                : filteredOrders
                .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                )
                .map((order) => {
                    const createdAt = new Date(order.created_at);
                    const isDownloadAllowed =
                    createdAt >=
                    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
                    return (
                        <TableRow key={order.id}>
                        <TableCell style={{ color: "#b0b0b0" }}>
                          {order.id}
                        </TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>
                          {order.order_info.fromName}
                        </TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>
                          {order.order_info.toName}
                        </TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>
                          {order.class}
                        </TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>
                          ${order.price}
                        </TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>
                          {order.transid}
                        </TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>
                          {order.trackId}
                        </TableCell>
                        <TableCell style={{ color: "#b0b0b0" }}>
                          {createdAt.toLocaleString()}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="View Order Details">
                            <Button
                              onClick={() => viewOrder(order.id)}
                              color="primary">
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Download Label">
                            <Button
                              onClick={() =>
                                downloadFile(
                                    order.transid,
                                    order.trackId,
                                    order.created_at
                                )
                            }
                              color="primary"
                              disabled={!order.transid || !isDownloadAllowed}>
                              <FontAwesomeIcon icon={faDownload} />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Track Package">
                            <Button
                              href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${order.trackId}`}
                              target="_blank"
                              color="primary">
                              <FontAwesomeIcon icon={faTruck} />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                })}
          </TableBody>
        </Table>
      </TableStyled>
      <PaginationStyled
        count={totalPages}
        page={currentPage}
        onChange={(e, page) => setCurrentPage(page)}
        sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
        />
      {orderDetails && (
          <Modal open={openModal} onClose={() => setOpenModal(false)}>
          {renderOrderDetails(orderDetails)}
        </Modal>
      )}
    </Box>
      </>
  );
}
