import React from 'react';
import { Link } from 'react-router-dom';

export default function Maintenance() {
    return (
        <div
            style={{
                backgroundColor: '#1a1a1a',
                color: '#fff',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '20px',
            }}
        >
            <h1
                style={{
                    fontSize: '3rem',
                    marginBottom: '20px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    letterSpacing: '2px',
                }}
            >
                We'll Be Back Soon!
            </h1>
            <p
                style={{
                    fontSize: '1.2rem',
                    lineHeight: '1.5',
                    marginBottom: '40px',
                    maxWidth: '600px',
                }}
            >
                Our team is currently working on some exciting updates to improve your experience.
                Please check back later. Thank you for your patience!
            </p>
            <div style={{ marginTop: '20px' }}>
                <Link
                    to="/"
                    style={{
                        textDecoration: 'none',
                        padding: '12px 24px',
                        backgroundColor: '#4ecca3',
                        color: '#000',
                        borderRadius: '8px',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        transition: 'background-color 0.3s ease',
                    }}
                    onMouseOver={(e) => (e.target.style.backgroundColor = '#3db291')}
                    onMouseOut={(e) => (e.target.style.backgroundColor = '#4ecca3')}
                >
                    Go Back Home
                </Link>
            </div>
        </div>
    );
}
