import jwtDecode from "jwt-decode";
import {
    IconHome,
    IconCreditCard,
    IconHistory,
    IconListDetails,
    IconTags,
    IconClipboardCheck,
    IconMapPin,
    IconSettings,
    IconSquares,
    IconLayout2,
    IconChecklist,
    IconTimelineEventPlus,
    IconUsers,
    IconBuildingBank,
    IconShoppingCart,
    IconTable,
    IconUserCircle,
} from "@tabler/icons-react";

// Decode JWT Token to extract is_admin property
const getIsAdmin = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;
    try {
        const decoded = jwtDecode(token); // Decodes the JWT token
        return decoded?.is_admin || false; // Check if `is_admin` exists and is true
    } catch (err) {
        console.error("Error decoding JWT token:", err);
        return false;
    }
};

const isAdmin = getIsAdmin(); // Call the function once

export const menuList = [
    {
        devider: "Main",
    },
    {
        icon: IconHome,
        link: "Dashboard",
        url: "/",
    },
    {
        icon: IconCreditCard,
        link: "Add Balance",
        url: "/deposit",
    },
    {
        icon: IconHistory,
        link: "Balance History",
        url: "/my-deposits",
    },
    {
        icon: IconListDetails,
        link: "Create Label",
        url: "/label",
    },
    {
        icon: IconTags,
        link: "Create Bulk",
        url: "/bulk",
    },
    {
        icon: IconClipboardCheck,
        link: "Order History",
        url: "/history",
    },
    {
        icon: IconClipboardCheck,
        link: "Bulk Order History",
        url: "/bulk-history",
    },
    {
        icon: IconTags,
        link: "Manifested Label",
        url: "/manifest",
    },
    {
        icon: IconTags,
        link: "Manifested History",
        url: "/manifest-history",
    },
    {
        icon: IconMapPin,
        link: "Manage Addresses",
        url: "/address",
    },
    {
        icon: IconSettings,
        link: "Settings",
        url: "/settings",
    },
    ...(isAdmin
        ? [
              {
                  devider: "Admin",
              },
              {
                  icon: IconSquares,
                  link: "Admin Tools",
                  children: [
                      {
                          icon: IconTable,
                          link: "Manage Bulk",
                          url: "/manage-bulk",
                      },
                      {
                          icon: IconShoppingCart,
                          link: "Manage Orders",
                          url: "/manage-orders",
                      },
                      {
                          icon: IconBuildingBank,
                          link: "Manage Price",
                          url: "/manage-price",
                      },
                      {
                          icon: IconUserCircle,
                          link: "Manage User Price",
                          url: "/manage-user-price",
                      },
                      {
                          icon: IconUsers,
                          link: "Manage Users",
                          url: "/manage-users",
                      },
                      {
                          icon: IconCreditCard,
                          link: "Manage Deposit",
                          url: "/manage-deposit",
                      },
                  ],
              },
          ]
        : []),
];
