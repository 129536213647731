import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import Index from '../pages/dashboard/index';
import AuthLayout from '../components/layout/AuthLayout';
import Signin from '../pages/auth/Signin';
import Signup from '../pages/auth/Signup';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Maintenance from '../pages/auth/Maintenance';
import PageNotFound from '../pages/auth/PageNotFound';



// user routes
import Payment from '../pages/dashboard/deopsit';
import Label from '../pages/dashboard/label';
import Bulk from '../pages/dashboard/bulk';
import History from '../pages/dashboard/history';
import BulkHistory from '../pages/dashboard/bulk-history';
import Settings from '../pages/dashboard/settings';
import Address from '../pages/dashboard/address-book';
import Manifest from '../pages/dashboard/manifest-label';
import ManifestHistory from '../pages/dashboard/manifest-history';
import MyDeposit from '../pages/dashboard/my_deposit';

//users
import Logout from '../pages/dashboard/logout'

// admin routes
import BulkForAdmin from '../pages/dashboard/admin_bulk_order';
import OrderAdmin from '../pages/dashboard/admin_order';
import ManagePrice from '../pages/dashboard/manage_price';
import ManageUserPrice from '../pages/dashboard/manage_user_pricing';
import ManageUsers from '../pages/dashboard/manage_users';
import ManageDeposit from '../pages/dashboard/manage_deposit';


export default function Routers() {
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    fetch('/config.json')
      .then((response) => response.json())
      .then((data) => {
        setIsMaintenance(data.isMaintenance);
      })
      .catch((error) => console.error('Error loading config:', error));
  }, []);

  const RouterContent = () => {
    const pageUrl = useLocation().pathname;

    useEffect(() => {
      const pageClass = pageUrl.substring(1).replace(/\//g, '-');
      document.body.classList.add(pageClass ? pageClass : 'dashboard');
      return () => {
        document.body.classList.remove(pageClass ? pageClass : 'dashboard');
      };
    }, [pageUrl]);

    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Index />} />
          <Route path="/deposit" element={<Payment />} />
          <Route path="/label" element={<Label />} />
          <Route path="/bulk" element={<Bulk />} />
          <Route path="/history" element={<History />} />
          <Route path="/bulk-history" element={<BulkHistory />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/address" element={<Address />} />
          <Route path="/manifest" element={<Manifest />} />
          <Route path="/manifest-history" element={<ManifestHistory />} />
          <Route path="/my-deposits" element={<MyDeposit />} />



          {/* admin routes */}
          <Route path="/manage-bulk" element={<BulkForAdmin />} />
          <Route path="/manage-orders" element={<OrderAdmin />} />
          <Route path="/manage-price" element={<ManagePrice />} />
          <Route path="/manage-user-price" element={<ManageUserPrice />} />
          <Route path="/manage-users" element={<ManageUsers />} />
          <Route path="/manage-deposit" element={<ManageDeposit />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Signin />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/auth-maintenance" element={<Maintenance />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    );
  };

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {isMaintenance ? (
        <Routes>
          <Route path="*" element={<Maintenance />} />
        </Routes>
      ) : (
        <RouterContent />
      )}
    </BrowserRouter>
  );
}
