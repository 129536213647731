import React from "react";
import { Link, Outlet } from "react-router-dom";
import CompanyLogo from "../common/CompanyLogo";
import { IconBrandDiscord, IconBrandTelegram } from "@tabler/icons-react";
import { Tooltip } from "@mui/material";

export default function AuthLayout() {
  const handleDiscordClick = () => {
    window.open("https://discord.gg/hiddenlabs", "_blank");
  };

  return (
    <div className='admin-wrapper min-h-svh py-6 px-4 flex items-center justify-center bg-body-color after:fixed after:w-full after:h-full after:start-0 after:top-0 after:opacity-90 after:bg-[url("../../assets/images/auth.png")]'>
      <div className="container-fluid">
        <div className="flex gap-15 w-full relative z-[1]">
          <div className="items-center justify-center w-full lg:flex hidden">
            <div className="max-w-[400px]">
               {/* <div className='mb-4'>
                                <CompanyLogo className="text-primary w-[116px] h-auto" />
                            </div> */}
              <p className="mb-12 text-[32px]/[40px] font-medium">
                Simplify Your Shipping with Our Service
              </p>
              <div className="mb-8">
                <p className="text-[24px]/[30px] font-semibold mb-2">
                  Dedicated Support
                </p>
                <p>
                  Our support team is available 24/7 to assist you with any
                  questions or issues, ensuring a smooth experience.
                </p>
              </div>
              <div className="mb-8">
                <p className="text-[24px]/[30px] font-semibold mb-2">
                  Instant Delivery
                </p>
                <p>
                  Get your order generated instantly upon request, helping you
                  keep up with fast-paced shipping demands.
                </p>
              </div>
              <div className="mb-12">
                <p className="text-[24px]/[30px] font-semibold mb-2">
                  Multiple Payment Options
                </p>
                <p>
                  We offer various payment methods, allowing you to pay securely
                  and conveniently with your preferred option.
                </p>
              </div>

              <div className="flex flex-wrap gap-4">
                <Link
                  to="#"
                  onClick={handleDiscordClick}
                  className="w-[34px] h-[34px] rounded-full bg-border-color flex items-center justify-center text-white transition-all hover:bg-secondary">
                  <IconBrandDiscord width="18" height="18" />
                </Link>
                <Tooltip title="Private for now" arrow>
                  <span className="w-[34px] h-[34px] rounded-full bg-border-color flex items-center justify-center text-white transition-all hover:bg-secondary cursor-not-allowed">
                    <IconBrandTelegram width="18" height="18" />
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-full">
            <div className="bg-card-color rounded-xl sm:p-4 p-2 max-w-[500px] w-full shadow-shadow-sm border border-dashed border-border-color">
              <div className="sm:max-h-[calc(100svh-48px-32px)] max-h-[calc(100svh-48px-16px)] sm:p-4 p-3 overflow-auto custom-scrollbar">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
