import React from 'react';
import { Link } from 'react-router-dom';

export default function ForgotPassword() {
  return (
    <>
      <p className='sm:text-[40px]/[48px] text-[30px]/[36px] font-medium mb-2 text-center'>
        Forgot Password?
      </p>
      <p className='text-center sm:mb-12 mb-6 text-font-color-100'>
        This feature is currently disabled for security reasons. Please make a ticket on Discord for further assistance.
      </p>
      <div className='text-center sm:mt-30 mt-6'>
        <Link to="/login" className='text-primary'>
          Back to Sign in
        </Link>
      </div>
    </>
  );
}
