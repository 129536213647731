import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Alert,
  Typography,
  IconButton,
  Box,
  styled,
} from "@mui/material";
import { LoadScriptNext, Autocomplete } from "@react-google-maps/api";
import { Loop } from "@mui/icons-material";
import axios from "axios";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useNavigate } from "react-router-dom";

const REACT_APP_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  "& .MuiInputBase-input": {
    color: "gray",
  },
  "& .MuiFormLabel-root": {
    color: "gray",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
  },
}));

const SectionBox = styled(Box)(({ theme }) => ({
  border: "1px dashed gray",
  borderRadius: "8px",
  padding: "16px",
  margin: "10px",
  flex: "1",
}));

const stateOptions = [
  { value: "", label: "Select State" },
  { value: "AL", label: "Alabama (AL)" },
  { value: "AK", label: "Alaska (AK)" },
  { value: "AZ", label: "Arizona (AZ)" },
  { value: "AR", label: "Arkansas (AR)" },
  { value: "CA", label: "California (CA)" },
  { value: "CO", label: "Colorado (CO)" },
  { value: "CT", label: "Connecticut (CT)" },
  { value: "DC", label: "District Of Columbia (DC)" },
  { value: "DE", label: "Delaware (DE)" },
  { value: "FL", label: "Florida (FL)" },
  { value: "GA", label: "Georgia (GA)" },
  { value: "HI", label: "Hawaii (HI)" },
  { value: "ID", label: "Idaho (ID)" },
  { value: "IL", label: "Illinois (IL)" },
  { value: "IN", label: "Indiana (IN)" },
  { value: "IA", label: "Iowa (IA)" },
  { value: "KS", label: "Kansas (KS)" },
  { value: "KY", label: "Kentucky (KY)" },
  { value: "LA", label: "Louisiana (LA)" },
  { value: "ME", label: "Maine (ME)" },
  { value: "MD", label: "Maryland (MD)" },
  { value: "MA", label: "Massachusetts (MA)" },
  { value: "MI", label: "Michigan (MI)" },
  { value: "MN", label: "Minnesota (MN)" },
  { value: "MS", label: "Mississippi (MS)" },
  { value: "MO", label: "Missouri (MO)" },
  { value: "MT", label: "Montana (MT)" },
  { value: "NE", label: "Nebraska (NE)" },
  { value: "NV", label: "Nevada (NV)" },
  { value: "NH", label: "New Hampshire (NH)" },
  { value: "NJ", label: "New Jersey (NJ)" },
  { value: "NM", label: "New Mexico (NM)" },
  { value: "NY", label: "New York (NY)" },
  { value: "NC", label: "North Carolina (NC)" },
  { value: "ND", label: "North Dakota (ND)" },
  { value: "OH", label: "Ohio (OH)" },
  { value: "OK", label: "Oklahoma (OK)" },
  { value: "OR", label: "Oregon (OR)" },
  { value: "PA", label: "Pennsylvania (PA)" },
  { value: "PR", label: "Puerto Rico (PR)" },
  { value: "RI", label: "Rhode Island (RI)" },
  { value: "SC", label: "South Carolina (SC)" },
  { value: "SD", label: "South Dakota (SD)" },
  { value: "TN", label: "Tennessee (TN)" },
  { value: "TX", label: "Texas (TX)" },
  { value: "UT", label: "Utah (UT)" },
  { value: "VT", label: "Vermont (VT)" },
  { value: "VA", label: "Virginia (VA)" },
  { value: "WA", label: "Washington (WA)" },
  { value: "WV", label: "West Virginia (WV)" },
  { value: "WI", label: "Wisconsin (WI)" },
  { value: "WY", label: "Wyoming (WY)" },
];
const LabelCreation = () => {
  const navigate = useNavigate();

  const [fromAddresses, setFromAddresses] = useState([]);
  const [toAddresses, setToAddresses] = useState([]);
  const [fromData, setFromData] = useState({
    name: "",
    phone: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [toData, setToData] = useState({
    name: "",
    phone: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [weight, setWeight] = useState("");
  const [serviceCode, setServiceCode] = useState("usps_priority");
  const [template, setTemplate] = useState("usps_priority");
  const [split, setSplit] = useState("0-3LB");
  const [price, setPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });

  const fromAutocompleteRef = useRef(null);
  const toAutocompleteRef = useRef(null);

  const generateRandomPhoneNumber = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000).toString();
  };

  const calculatePrice = () => {
    const pricing = {
      usps_priority: { "0-3LB": 3, "3-15LB": 4, "15-50LB": 5 },
      usps_ground: { "0-3LB": 2.5, "3-15LB": 3.5, "15-50LB": 5 },
    };
    const selectedPrice = pricing[serviceCode]?.[split] || 0;
    setPrice(selectedPrice);
  };

  const handlePlaceSelect = (type) => {
    const autocomplete =
      type === "from" ? fromAutocompleteRef.current : toAutocompleteRef.current;
    const place = autocomplete.getPlace();
    const mainAddress = place.name || "";
    const components = place.address_components;
    const city =
      components.find((comp) => comp.types.includes("locality"))?.long_name ||
      "";
    const state =
      components.find((comp) =>
        comp.types.includes("administrative_area_level_1")
      )?.short_name || "";
    const zip =
      components.find((comp) => comp.types.includes("postal_code"))
        ?.long_name || "";

    const data = { street: mainAddress, city, state, zip };

    if (type === "from") {
      setFromData((prev) => ({ ...prev, ...data }));
    } else {
      setToData((prev) => ({ ...prev, ...data }));
    }
  };

  const handleFromSelect = (event) => {
    const selectedId = event.target.value;
    const selectedAddress = fromAddresses.find(
      (address) => address.id === selectedId
    );
    if (selectedAddress) {
      setFromData({
        name: selectedAddress.name,
        phone: selectedAddress.phone,
        street: selectedAddress.address1,
        street2: selectedAddress.address2 || "",
        city: selectedAddress.city,
        state: selectedAddress.state,
        zip: selectedAddress.zipcode,
      });
    }
  };

  const handleToSelect = (event) => {
    const selectedId = event.target.value;
    const selectedAddress = toAddresses.find(
      (address) => address.id === selectedId
    );
    if (selectedAddress) {
      setToData({
        name: selectedAddress.name,
        phone: selectedAddress.phone,
        street: selectedAddress.address1,
        street2: selectedAddress.address2 || "",
        city: selectedAddress.city,
        state: selectedAddress.state,
        zip: selectedAddress.zipcode,
      });
    }
  };

  useEffect(() => {
    setFromData((prev) => ({
      ...prev,
      phone: generateRandomPhoneNumber(),
    }));
    setToData((prev) => ({
      ...prev,
      phone: generateRandomPhoneNumber(),
    }));
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const formatPhoneNumber = (phone) => phone.replace(/[^0-9]/g, "");

      const payload = {
        labelType: serviceCode || "usps_priority",
        weight: Number(weight) || 5,
        FromName: fromData.name || "",
        FromPhone: formatPhoneNumber(fromData.phone || ""),
        FromStreet: fromData.street.trim() || "",
        FromCity: fromData.city.trim() || "",
        FromState: fromData.state.trim() || "",
        FromZip: fromData.zip.trim() || "",
        ToName: toData.name || "",
        ToPhone: formatPhoneNumber(toData.phone || ""),
        ToStreet: toData.street.trim() || "",
        ToCity: toData.city.trim() || "",
        ToState: toData.state.trim() || "",
        ToZip: toData.zip.trim() || "",
        type: serviceCode.toLowerCase().replace(" ", "_") || "usps_ground",
        split: split || "0-3LB",
      };

      Object.keys(payload).forEach((key) => {
        if (payload[key] === "" || payload[key] === " ") {
          delete payload[key];
        }
      });

      console.log("Payload sent to API:", payload);

      const response = await axios.post(
        "http://localhost:3000/api/create-manifest-label",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      const message =
        response.data.message ||
        "Label created successfully. Webhook will handle tracking updates.";

      if (message.includes("Label created successfully")) {
        setNotification({
          message,
          type: "success",
        });

        setTimeout(() => {
          navigate("/manifest-history");
        }, 2000);
      } else {
        setNotification({
          message,
          type: "info",
        });
      }
    } catch (error) {
      console.error("Error during API request:", error);
      setNotification({ message: "Error creating label", type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          "http://localhost:3000/api/view-addresses",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
            },
          }
        );
        const addresses = response.data.data;
        setFromAddresses(addresses.filter((address) => address.type === 1));
        setToAddresses(addresses.filter((address) => address.type === 0));
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };
    fetchAddresses();
  }, []);
  const breadcrumbItem = [{ name: "Create Manifest Label" }];

  return (
    <>
      <div style={{ marginTop: "15px", marginLeft: "35px" }}>
        <Breadcrumb breadcrumbItem={breadcrumbItem} />
        <WelcomeHeader />
      </div>
      <LoadScriptNext
        googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places"]}>
        <div style={{ padding: "20px" }}>
          <Box display="flex" justifyContent="space-between">
            <SectionBox>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                style={{
                  color: "font-color",
                  fontWeight: "bold",
                }}>
                From Address
              </Typography>
              <StyledTextField
                label="Select Sender"
                fullWidth
                select
                margin="normal"
                value={fromData.name || ""}
                onChange={handleFromSelect}>
                {fromAddresses.map((address) => (
                  <MenuItem key={address.id} value={address.id}>
                    {address.address_name}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                label="Name"
                fullWidth
                margin="normal"
                value={fromData.name}
                onChange={(e) =>
                  setFromData((prev) => ({ ...prev, name: e.target.value }))
                }
              />
              <Box display="flex" alignItems="center" marginBottom="16px">
                <StyledTextField
                  label="Phone"
                  fullWidth
                  value={fromData.phone}
                  onChange={(e) =>
                    setFromData((prev) => ({ ...prev, phone: e.target.value }))
                  }
                />
                <IconButton
                  color="primary"
                  onClick={() =>
                    setFromData((prev) => ({
                      ...prev,
                      phone: generateRandomPhoneNumber(),
                    }))
                  }>
                  <Loop />
                </IconButton>
              </Box>
              <Autocomplete
                onLoad={(autocomplete) => {
                  fromAutocompleteRef.current = autocomplete;
                }}
                onPlaceChanged={() => handlePlaceSelect("from")}>
                <StyledTextField
                  label="Street"
                  fullWidth
                  margin="normal"
                  value={fromData.street}
                  onChange={(e) =>
                    setFromData((prev) => ({ ...prev, street: e.target.value }))
                  }
                />
              </Autocomplete>
              <StyledTextField
                label="Street Line 2"
                fullWidth
                margin="normal"
                value={fromData.street2}
                onChange={(e) =>
                  setFromData((prev) => ({ ...prev, street2: e.target.value }))
                }
              />
              <StyledTextField
                label="City"
                fullWidth
                margin="normal"
                value={fromData.city}
                onChange={(e) =>
                  setFromData((prev) => ({ ...prev, city: e.target.value }))
                }
              />
              <StyledTextField
                label="State"
                fullWidth
                select
                margin="normal"
                value={fromData.state}
                onChange={(e) =>
                  setFromData((prev) => ({ ...prev, state: e.target.value }))
                }>
                {stateOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                label="Zip"
                fullWidth
                margin="normal"
                value={fromData.zip}
                onChange={(e) =>
                  setFromData((prev) => ({ ...prev, zip: e.target.value }))
                }
              />
            </SectionBox>
            <SectionBox>
            <Typography
                variant="h5"
                gutterBottom
                align="center"
                style={{
                  color: "font-color",
                  fontWeight: "bold",
                }}>
                To Address
              </Typography>
              <StyledTextField
                label="Select Recipient"
                fullWidth
                select
                margin="normal"
                value={toData.name || ""}
                onChange={handleToSelect}>
                {toAddresses.map((address) => (
                  <MenuItem key={address.id} value={address.id}>
                    {address.address_name}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                label="Name"
                fullWidth
                margin="normal"
                value={toData.name}
                onChange={(e) =>
                  setToData((prev) => ({ ...prev, name: e.target.value }))
                }
              />
              <Box display="flex" alignItems="center" marginBottom="16px">
                <StyledTextField
                  label="Phone"
                  fullWidth
                  value={toData.phone}
                  onChange={(e) =>
                    setToData((prev) => ({ ...prev, phone: e.target.value }))
                  }
                />
                <IconButton
                  color="primary"
                  onClick={() =>
                    setToData((prev) => ({
                      ...prev,
                      phone: generateRandomPhoneNumber(),
                    }))
                  }>
                  <Loop />
                </IconButton>
              </Box>
              <Autocomplete
                onLoad={(autocomplete) => {
                  toAutocompleteRef.current = autocomplete;
                }}
                onPlaceChanged={() => handlePlaceSelect("to")}>
                <StyledTextField
                  label="Street"
                  fullWidth
                  margin="normal"
                  value={toData.street}
                  onChange={(e) =>
                    setToData((prev) => ({ ...prev, street: e.target.value }))
                  }
                />
              </Autocomplete>
              <StyledTextField
                label="Street Line 2"
                fullWidth
                margin="normal"
                value={toData.street2}
                onChange={(e) =>
                  setToData((prev) => ({ ...prev, street2: e.target.value }))
                }
              />
              <StyledTextField
                label="City"
                fullWidth
                margin="normal"
                value={toData.city}
                onChange={(e) =>
                  setToData((prev) => ({ ...prev, city: e.target.value }))
                }
              />
              <StyledTextField
                label="State"
                fullWidth
                select
                margin="normal"
                value={toData.state}
                onChange={(e) =>
                  setToData((prev) => ({ ...prev, state: e.target.value }))
                }>
                {stateOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                label="Zip"
                fullWidth
                margin="normal"
                value={toData.zip}
                onChange={(e) =>
                  setToData((prev) => ({ ...prev, zip: e.target.value }))
                }
              />
            </SectionBox>
            <SectionBox>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                style={{
                  color: "font-color",
                  fontWeight: "bold",
                }}>
                Package Information{" "}
              </Typography>{" "}
              <StyledTextField
                label="Weight"
                fullWidth
                margin="normal"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
              <StyledTextField
                label="Service"
                fullWidth
                select
                margin="normal"
                value={serviceCode}
                onChange={(e) => {
                  setServiceCode(e.target.value);
                  calculatePrice();
                }}>
                <MenuItem value="usps_priority">USPS Priority</MenuItem>
                <MenuItem value="usps_ground">USPS Ground</MenuItem>
              </StyledTextField>
              <StyledTextField
                label="Split"
                fullWidth
                select
                margin="normal"
                value={split}
                onChange={(e) => {
                  setSplit(e.target.value);
                  calculatePrice();
                }}>
                <MenuItem value="0-3LB">0-3LB</MenuItem>
                <MenuItem value="3-15LB">3-15LB</MenuItem>
                <MenuItem value="15-50LB">15-50LB</MenuItem>
              </StyledTextField>
              {price && (
               <Typography
               variant="h6"
               sx={{
                 marginTop: "10px",
                 textAlign: "center",
                 color: "gray",
                 fontWeight: "bold", 
               }}
             >
               Price: ${price}
             </Typography>
             
              )}
            </SectionBox>
          </Box>
          <Button
            onClick={handleSubmit}
            variant="contained"
            style={{
              marginTop: "20px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              padding: "12px 24px",
              borderRadius: "8px",
              fontSize: "1.1rem",
              fontWeight: "bold",
              backgroundColor: "#ffffff",
              color: "#000000",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s, box-shadow 0.2s",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#f0f0f0";
              e.currentTarget.style.color = "#333333";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "#ffffff";
              e.currentTarget.style.color = "#000000";
            }}
            onMouseDown={(e) => {
              e.currentTarget.style.transform = "scale(0.98)";
              e.currentTarget.style.boxShadow =
                "0px 2px 6px rgba(0, 0, 0, 0.2)";
            }}
            onMouseUp={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow =
                "0px 4px 10px rgba(0, 0, 0, 0.2)";
            }}>
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Create Manifest Label"
            )}
          </Button>
          {notification.message && (
            <Alert severity={notification.type} style={{ marginTop: "20px" }}>
              {notification.message}
            </Alert>
          )}
        </div>
      </LoadScriptNext>
    </>
  );
};

export default LabelCreation;
