import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  InputLabel,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CloudUpload } from "@mui/icons-material";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useNavigate } from "react-router-dom";

const DragDropArea = styled(Box)({
  border: "2px dotted var(--border-color)",
  padding: 40,
  textAlign: "center",
  borderRadius: 8,
  transition: "background-color 0.3s",
  cursor: "pointer",
  backgroundColor: "var(--card-color)",
  color: "#b0b0b0",
});

const breadcrumbItem = [{ name: "Create Bulk Labels" }];

const TableStyled = styled(TableContainer)({
  marginTop: 20,
  borderRadius: 8,
  color: "#ffffff",
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
});

export default function BulkLabelPage() {

  const navigate = useNavigate();
  const [serviceCode, setServiceCode] = useState("USPS_GROUND_15");
  const [template, setTemplate] = useState("BASIC");
  const [csvData, setCsvData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileName, setFileName] = useState(
    "Drag & drop your CSV file here or click to select"
  );
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(null);
  const itemsPerPage = 10;
  const csvFileInput = useRef();

  const handleServiceChange = async (e) => {
    setServiceCode(e.target.value);
    if (csvData.length > 0) {
      fetchPriceAndQuantity(csvData);
    }
  };

  const handleTemplateChange = (e) => setTemplate(e.target.value);

  const parseCSV = (csvText) => {
    const rows = csvText
      .trim()
      .split("\n")
      .map((row) => row.split(","));
    return rows;
  };

  const handleCSVUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.name.endsWith(".csv")) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvText = event.target.result;
        const parsedData = parseCSV(csvText);
        setCsvData(parsedData);
        fetchPriceAndQuantity(parsedData);
      };
      reader.readAsText(file);
    } else {
      setNotification("Please upload a valid CSV file.");
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.name.endsWith(".csv")) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvText = event.target.result;
        const parsedData = parseCSV(csvText);
        setCsvData(parsedData);
        fetchPriceAndQuantity(parsedData);
      };
      reader.readAsText(file);
    } else {
      setNotification("Please upload a valid CSV file.");
    }
  };

  const fetchPriceAndQuantity = async (data) => {
    setLoading(true);
    try {
      const quantity = data.length - 1;
      const response = await fetch(
        "http://localhost:3000/api/get-price-for-bulk-order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            service_code: serviceCode,
            quantity,
          }),
        }
      );
      const result = await response.json();
      setTotalPrice(`Total Price: $${result.total_price}`);
    } catch (error) {
      setNotification("Error fetching price");
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!csvFileInput.current.files[0])
      return setNotification("Please upload a CSV file");
    setLoading(true);
    const formData = new FormData();
    formData.append("service_code", serviceCode);
    formData.append("template", template);
    formData.append("file", csvFileInput.current.files[0]);
    try {
      const response = await fetch("http://localhost:3000/api/bulk", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
        },
        body: formData,
      });
      if (response.ok) {
        setNotification("Bulk orders processed successfully");
        setTimeout(() => {
          navigate("/bulk-history");
        }, 1000);
      } else {
        setNotification("Error processing bulk orders");
      }
    } catch (error) {
      setNotification("Error processing bulk orders");
    }
    setLoading(false);
  };

  const displayCSVData = csvData.slice(
    (currentPage - 1) * itemsPerPage + 1,
    currentPage * itemsPerPage + 1
  );

  return (
    <>
      <div style={{ marginTop: "15px", marginLeft: "35px" }}>
        <Breadcrumb breadcrumbItem={breadcrumbItem} />
        <WelcomeHeader />
      </div>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 10,
          color: "#ffffff",
        }}>
        <div
          className="card rounded-xl md:p-6 p-4 border border-dashed border-border-color"
          style={{
            maxWidth: "600px",
            width: "100%",
            marginTop: "10px",
              border: "1px dashed gray"
          }}>
          {notification && <Alert severity="info">{notification}</Alert>}
          <FormControl fullWidth margin="normal" sx={{ color: "#b0b0b0" }}>
            <InputLabel sx={{ color: "#b0b0b0" }}>Service Code</InputLabel>
            <Select
              value={serviceCode}
              onChange={handleServiceChange}
              sx={{ color: "gray" }}>
              <MenuItem value="USPS_GROUND_15">USPS Ground 1-15 OZ</MenuItem>
              <MenuItem value="USPS_GROUND_70">USPS Ground 1-70 LB</MenuItem>
              <MenuItem value="USPS_PRIORITY">USPS Priority 1-70 LB</MenuItem>
              <MenuItem value="USPS_EXPRESS">USPS Express 1-70 LB</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" sx={{ color: "#b0b0b0" }}>
            <InputLabel sx={{ color: "#b0b0b0" }}>Provider</InputLabel>
            <Select
              value={template}
              onChange={handleTemplateChange}
              sx={{ color: "gray" }}>
              <MenuItem value="BASIC">SHIPPO</MenuItem>
              <MenuItem value="EVS">EVS</MenuItem>
              <MenuItem value="EVS_V2">EVS V2</MenuItem>
              <MenuItem value="AMZ">AMAZON</MenuItem>
              <MenuItem value="ROLLO">Rollo</MenuItem>
            </Select>
          </FormControl>
          <Box mb={3}>
            <InputLabel sx={{ color: "#b0b0b0" }}>Upload CSV</InputLabel>
            <DragDropArea
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleFileDrop}
              onClick={() => csvFileInput.current.click()}>
              <CloudUpload
                style={{ fontSize: 50, color: "#ffffff", marginBottom: 10 }}
              />
              <Typography>{fileName}</Typography>
              <input
                type="file"
                accept=".csv"
                ref={csvFileInput}
                onChange={handleCSVUpload}
                style={{ display: "none" }}
              />
            </DragDropArea>
          </Box>
          {totalPrice && (
            <Typography
              variant="h6"
              style={{
                marginBottom: 10,
                textAlign: "center",
                color: "#4ecca3",
              }}>
              {totalPrice}
            </Typography>
          )}
          <Button
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "#ffffff",
              color: "#000000",
              "&:hover": { backgroundColor: "#f0f0f0" },
              marginTop: 2,
            }}
            disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Generate Labels"}
          </Button>
          <Box mt={2} textAlign="center">
            <Typography
              component="a"
              href="template.csv"
              download="template.csv"
              style={{
                color: "#4ecca3",
                cursor: "pointer",
                textDecoration: "underline",
                
              }}>
              Click here to download the template file
            </Typography>
          </Box>
        </div>
        <TableStyled component={Paper} style={{ marginTop: 20 }}>
          <Table
            aria-label="CSV Table"
            style={{
                border: "1px dashed gray",
              backgroundColor: "var(--card-color)",
            }}>
            <TableHead>
              <TableRow>
                {csvData[0] &&
                  csvData[0].map((header, index) => (
                    <TableCell
                      key={index}
                      style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                      {header}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayCSVData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex} style={{ color: "#b0b0b0" }}>
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableStyled>
        <Pagination
          count={Math.ceil((csvData.length - 1) / itemsPerPage)}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        />
      </Box>
    </>
  );
}
