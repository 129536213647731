import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";


const breadcrumbItem = [
    { name: "Admin" },
    { name: "Manage Bulk"}
  ];


const TableStyled = styled(TableContainer)({
    marginTop: 20,
    borderRadius: 8,
    color: "#ffffff",
    border: "2px dotted var(--border-color)",
    backgroundColor: "var(--card-color)",
  });

  const PaginationStyled = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "gray",
    },
    "& .Mui-selected": {
      backgroundColor: "#4ecca3",
      color: "#fff",
    },
  });

const ManageBulkOrders = () => {
  const [bulkOrders, setBulkOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [notification, setNotification] = useState({ message: "", error: false });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  useEffect(() => {
    const checkAdmin = () => {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        if (!decodedToken.is_admin) {
          navigate("/");
        }
      } catch (err) {
        navigate("/");
      }
    };
    checkAdmin();
  }, [navigate, token]);

  const fetchBulkOrders = async (page = 1) => {
    try {
      const response = await fetch(`http://localhost:3000/api/admin/bulk-orders?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.message === "Bulk orders fetched successfully") {
        setBulkOrders(data.bulkOrders);
        setFilteredOrders(data.bulkOrders);
        setTotalPages(data.totalPages || 1);
      } else {
        setNotification({ message: data.message, error: true });
      }
    } catch (error) {
      setNotification({ message: "Error fetching orders", error: true });
    }
  };

  useEffect(() => {
    fetchBulkOrders(currentPage);
  }, [currentPage]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    const filtered = bulkOrders.filter(
      (order) =>
        order.id.toString().includes(event.target.value) ||
        order.user_id.toString().includes(event.target.value) ||
        order.service_code.toLowerCase().includes(event.target.value.toLowerCase()) ||
        order.trans_ids.some((id) => id.toString().includes(event.target.value))
    );
    setFilteredOrders(filtered);
  };

  const handleRefund = async (orderId) => {
    try {
      const response = await fetch("http://localhost:3000/api/admin/refund-order-for-bulk", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ bulk_order_id: orderId }),
      });
      const data = await response.json();
      setNotification({ message: data.message, error: data.message !== "User balance updated successfully for bulk order refund" });
      fetchBulkOrders(currentPage);
    } catch (error) {
      setNotification({ message: "Error processing refund", error: true });
    }
  };

  const renderOrders = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const ordersToDisplay = filteredOrders.slice(startIndex, startIndex + itemsPerPage);

    return ordersToDisplay.map((order) => (
      <TableRow key={order.id}>
        <TableCell style={{ color: "#b0b0b0" }}>{order.id}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{order.user_id}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{order.service_code}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>${order.price}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{order.quantity}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{order.trans_ids[0]}</TableCell>
        <TableCell>
  {order.is_minused === 0 ? (
    <Box
      sx={{
        display: "inline-block",
        py: "2px",
        px: "8px",
        borderRadius: "4px",
        backgroundColor: "#17a2b8",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      Not Minused
    </Box>
  ) : (
    <Box
      sx={{
        display: "inline-block",
        py: "2px",
        px: "8px",
        borderRadius: "4px",
        backgroundColor: "#28a745",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      Minused
    </Box>
  )}
</TableCell>

        <TableCell style={{ color: "#b0b0b0" }}>{new Date(order.created_at).toLocaleDateString()}</TableCell>
        <TableCell>
  <Tooltip title="Refund Order" arrow>
    <Button
      onClick={() => handleRefund(order.id)}
      color="success"
      style={{ minWidth: "auto", padding: 0 }}
    >
      <FontAwesomeIcon icon={faUndoAlt} style={{ color: "#4ecca3", fontSize: "1.2rem" }} />
    </Button>
  </Tooltip>
</TableCell>
      </TableRow>
    ));
  };

  return (
    <>
        <div style={{ marginTop: "15px", marginLeft: "35px" }}>

       <Breadcrumb breadcrumbItem={breadcrumbItem} />
       <WelcomeHeader />
       </div>
    <Box sx={{ padding: 3 }}>
      {notification.message && (
          <Alert severity={notification.error ? "error" : "success"} sx={{ mb: 2 }}>
          {notification.message}
        </Alert>
      )}

      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
  <TextField
    value={searchValue}
    onChange={handleSearch}
    placeholder="Search by ID, User ID, Service Code, Trans ID..."
    variant="outlined"
    sx={{
        width: "300px",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "gray", 
                borderStyle: "dotted",
            },
            "&:hover fieldset": {
                borderColor: "lightgray", 
            },
            "&.Mui-focused fieldset": {
                borderColor: "#4ecca3",
            },
            color: "gray",
        },
        "& .MuiInputBase-input": {
            color: "gray", 
        },
        "& .MuiInputBase-input::placeholder": {
            color: "gray", 
      },
    }}
    />
</Box>


      <TableStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>ID</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>User ID</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Service Code</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Price</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Quantity</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Trans ID</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Created At</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderOrders()}</TableBody>
        </Table>
      </TableStyled>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <PaginationStyled
          count={totalPages}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
          />
      </Box>
    </Box>
          </>
  );
};

export default ManageBulkOrders;
