import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  CircularProgress,
  Alert,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: "20px",
  backgroundColor: "var(--card-color)",
  color: "#ffffff",
  borderRadius: "8px",
  border: "2px dotted var(--border-color)",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
    margin: "0 auto",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const SubmitButton = styled(Button)({
  backgroundColor: "#ffffff",
  color: "#000000",
  padding: "12px",
  border: "none",
  borderRadius: "50px",
  fontSize: "16px",
  cursor: "pointer",
  width: "100%",
  maxWidth: "200px",
  margin: "20px auto 0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default function SettingsPage() {
  const [notification, setNotification] = useState({ message: "", error: false });
  const [loading, setLoading] = useState(false);
  const [passwordData, setPasswordData] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [emailData, setEmailData] = useState({
    current_email: "",
    new_email: "",
  });

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await fetch("http://localhost:3000/api/change-password", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passwordData),
      });
      const result = await response.json();
      if (result.message === "Password updated successfully") {
        setNotification({ message: result.message, error: false });
        setPasswordData({ old_password: "", new_password: "", confirm_new_password: "" });
      } else {
        setNotification({ message: result.message, error: true });
      }
    } catch (error) {
      setNotification({ message: "An error occurred", error: true });
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await fetch("http://localhost:3000/api/change-email", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });
      const result = await response.json();
      if (result.message === "Email updated successfully") {
        setNotification({ message: result.message, error: false });
        setEmailData({ current_email: "", new_email: "" });
      } else {
        setNotification({ message: result.message, error: true });
      }
    } catch (error) {
      setNotification({ message: "An error occurred", error: true });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <div style={{ marginTop: "15px", marginLeft: "35px" }}>
      <Breadcrumb breadcrumbItem={[{ name: "Settings" }, { name: "Change Password & Email" }]} />
      <WelcomeHeader />
      </div>
      <Box sx={{ padding: 3 }}>
        {notification.message && (
          <Alert severity={notification.error ? "error" : "success"} sx={{ mb: 2 }}>
            {notification.message}
          </Alert>
        )}

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6} container justifyContent="center">
            <FormContainer>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{ fontWeight: "bold", color: "gray" }}
                >
                Change Password
              </Typography>

              <form onSubmit={handlePasswordChange}>
                <TextField
                  label="Old Password"
                  type="password"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                  value={passwordData.old_password}
                  onChange={(e) =>
                    setPasswordData({ ...passwordData, old_password: e.target.value })
                  }
                  InputLabelProps={{ style: { color: "gray" } }}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                  />
                <TextField
                  label="New Password"
                  type="password"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                  value={passwordData.new_password}
                  onChange={(e) =>
                    setPasswordData({ ...passwordData, new_password: e.target.value })
                  }
                  InputLabelProps={{ style: { color: "gray" } }}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                  />
                <TextField
                  label="Confirm New Password"
                  type="password"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                  value={passwordData.confirm_new_password}
                  onChange={(e) =>
                    setPasswordData({ ...passwordData, confirm_new_password: e.target.value })
                  }
                  InputLabelProps={{ style: { color: "gray" } }}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                  />
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: "#000000" }} />
                  ) : (
                    "Change Password"
                  )}
                </SubmitButton>
              </form>
            </FormContainer>
          </Grid>

          <Grid item xs={12} md={6} container justifyContent="center">
            <FormContainer>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{ fontWeight: "bold", color: "gray" }}
                >
                Change Email
              </Typography>

              <form onSubmit={handleEmailChange}>
                <TextField
                  label="Current Email"
                  type="email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                  value={emailData.current_email}
                  onChange={(e) =>
                    setEmailData({ ...emailData, current_email: e.target.value })
                  }
                  InputLabelProps={{ style: { color: "gray" } }}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                  />
                <TextField
                  label="New Email"
                  type="email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                  value={emailData.new_email}
                  onChange={(e) =>
                    setEmailData({ ...emailData, new_email: e.target.value })
                  }
                  InputLabelProps={{ style: { color: "gray" } }}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                  />
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: "#000000" }} />
                  ) : (
                    "Change Email"
                  )}
                </SubmitButton>
              </form>
            </FormContainer>
          </Grid>
        </Grid>
      </Box>
 
                  </>
  );
}
