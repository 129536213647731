import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Modal,
  TextField,
  Tooltip,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";

const breadcrumbItem = [{ name: "Admin" }, { name: "Manage Price" }];

const TableStyled = styled(TableContainer)({
  marginTop: 20,
  borderRadius: 8,
  color: "#ffffff",
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
});

const ModalContainer = styled(Box)({
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
  borderRadius: 10,
  padding: 20,
  color: "#b0b0b0",
  maxWidth: "600px",
  margin: "auto",
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
  textAlign: "center",
});

const PricingManagement = () => {
  const [pricings, setPricings] = useState([]);
  const [notification, setNotification] = useState({
    message: "",
    error: false,
  });
  const [currentPricing, setCurrentPricing] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("token");

  const fetchPricings = async () => {
    setLoading(true);
    try {
      const response = await fetch("http://localhost:3000/api/get-all-prices", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setPricings(data);
    } catch (error) {
      setNotification({ message: "Failed to fetch pricings", error: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPricings();
  }, []);

  const handleEditClick = (pricing) => {
    setCurrentPricing(pricing);
    setOpenModal(true);
  };

  const handleSave = async () => {
    if (!currentPricing) return;

    const { id, price, bulk_price, bulk_min_quantity } = currentPricing;

    try {
      const response = await fetch(
        `http://localhost:3000/api/update-price/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ price, bulk_price, bulk_min_quantity }),
        }
      );
      const data = await response.json();

      if (data.message === "Price updated successfully") {
        setNotification({
          message: "Pricing updated successfully",
          error: false,
        });
        fetchPricings();
        setOpenModal(false);
      } else {
        setNotification({ message: data.message, error: true });
      }
    } catch (error) {
      setNotification({ message: "Error updating pricing", error: true });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentPricing(null);
  };

  const renderPricings = () => {
    return pricings.map((pricing) => (
      <TableRow key={pricing.id}>
        <TableCell style={{ color: "#b0b0b0" }}>{pricing.id}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>
          {pricing.service_code}
        </TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>${pricing.price}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>
          ${pricing.bulk_price}
        </TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>
          {pricing.bulk_min_quantity}
        </TableCell>
        <Tooltip title="Edit Price">
        <TableCell>
          <Button
            onClick={() => handleEditClick(pricing)}
            color="primary"
            sx={{ minWidth: "auto", padding: "6px" }}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </TableCell>
        </Tooltip>
      </TableRow>
    ));
  };

  return (
    <>
      <div style={{ marginTop: "15px", marginLeft: "35px" }}>
        <Breadcrumb breadcrumbItem={breadcrumbItem} />
        <WelcomeHeader />
      </div>
      <Box sx={{ padding: 3 }}>
        {notification.message && (
          <Alert
            severity={notification.error ? "error" : "success"}
            sx={{ mb: 2 }}>
            {notification.message}
          </Alert>
        )}

        <TableStyled component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>
                  ID
                </TableCell>
                <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>
                  Service Code
                </TableCell>
                <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>
                  Price
                </TableCell>
                <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>
                  Bulk Price
                </TableCell>
                <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>
                  Bulk Min Quantity
                </TableCell>
                <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    style={{ textAlign: "center", color: "#b0b0b0" }}>
                    Loading...
                  </TableCell>
                </TableRow>
              ) : (
                renderPricings()
              )}
            </TableBody>
          </Table>
        </TableStyled>

        {/* Edit Modal */}
        <Modal open={openModal} onClose={handleCloseModal}>
          <ModalContainer>
            <Typography
              variant="h6"
              sx={{ color: "#fff", marginBottom: 2, fontWeight: "bold" }}>
              Edit Pricing
            </Typography>

            <TextField
              label="Price"
              type="number"
              value={currentPricing?.price || ""}
              onChange={(e) =>
                setCurrentPricing({ ...currentPricing, price: e.target.value })
              }
              fullWidth
              sx={{
                mb: 2,
                "& .MuiInputBase-input": { color: "#b0b0b0" },
                "& .MuiInputLabel-root": { color: "#b0b0b0" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#b0b0b0",
                },
              }}
              InputLabelProps={{
                style: { color: "#b0b0b0" },
              }}
            />

            <TextField
              label="Bulk Price"
              type="number"
              value={currentPricing?.bulk_price || ""}
              onChange={(e) =>
                setCurrentPricing({
                  ...currentPricing,
                  bulk_price: e.target.value,
                })
              }
              fullWidth
              sx={{
                mb: 2,
                "& .MuiInputBase-input": { color: "#b0b0b0" },
                "& .MuiInputLabel-root": { color: "#b0b0b0" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#b0b0b0",
                },
              }}
              InputLabelProps={{
                style: { color: "#b0b0b0" },
              }}
            />

            <TextField
              label="Bulk Min Quantity"
              type="number"
              value={currentPricing?.bulk_min_quantity || ""}
              onChange={(e) =>
                setCurrentPricing({
                  ...currentPricing,
                  bulk_min_quantity: e.target.value,
                })
              }
              fullWidth
              sx={{
                mb: 2,
                "& .MuiInputBase-input": { color: "#b0b0b0" },
                "& .MuiInputLabel-root": { color: "#b0b0b0" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#b0b0b0",
                },
              }}
              InputLabelProps={{
                style: { color: "#b0b0b0" },
              }}
            />

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <Button onClick={handleCloseModal} color="error">
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary">
                Save
              </Button>
            </Box>
          </ModalContainer>
        </Modal>
      </Box>
    </>
  );
};

export default PricingManagement;
