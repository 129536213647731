import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          navigate("/login"); 
          return;
        }

        const response = await fetch("http://localhost:3000/api/logout-user-from-the-app", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
          },
        });

        if (response.ok) {
          localStorage.removeItem("token");
        } else {
          console.error("Logout failed from server.");
        }
      } catch (error) {
        console.error("Host not responding or network error:", error);
      } finally {
        localStorage.removeItem("token");
        navigate("/login");
      }
    };

    logoutUser();
  }, [navigate]);

  return null; 
};

export default Logout;
