import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  TextField,
  Alert,
  Paper,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";


const TableStyled = styled(TableContainer)({
  marginTop: 20,
  borderRadius: 8,
  color: "#ffffff",
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
});

const ModalContainer = styled(Box)({
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
  borderRadius: 10,
  padding: 20,
  color: "#b0b0b0",
  maxWidth: "600px",
  margin: "auto",
  position: "relative",
  top: "50%",
  transform: "translateY(-50%)",
  textAlign: "center",
});

const ManageUserPrices = () => {
  const [prices, setPrices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [notification, setNotification] = useState({ message: "", error: false });
  const [openModal, setOpenModal] = useState(false);
  const [modalMode, setModalMode] = useState("edit");
  const [currentPrice, setCurrentPrice] = useState({
    user_id: "",
    USPS_GROUND_15: "",
    USPS_GROUND_70: "",
    USPS_PRIORITY: "",
    USPS_EXPRESS: "",
  });

  const token = localStorage.getItem("token");

  const fetchPrices = async (page) => {
    try {
      const response = await fetch(
        `http://localhost:3000/api/user-prices?page=${page}&limit=10`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      setPrices(data.data || []);
      setCurrentPage(data.pagination?.currentPage || 1);
      setTotalPages(data.pagination?.totalPages || 1);
    } catch (error) {
      setNotification({ message: "Error fetching prices", error: true });
    }
  };

  useEffect(() => {
    fetchPrices(currentPage);
  }, [currentPage]);

  const handleOpenModal = (mode, price = null) => {
    setModalMode(mode);
    setCurrentPrice(
      mode === "edit" && price
        ? price
        : {
            user_id: "",
            USPS_GROUND_15: "",
            USPS_GROUND_70: "",
            USPS_PRIORITY: "",
            USPS_EXPRESS: "",
          }
    );
    setOpenModal(true);
  };

  const handleSave = async () => {
    try {
      const method = modalMode === "edit" ? "PUT" : "POST";
      const url = `http://localhost:3000/api/user-prices`;
      const bodyData = {
        ...currentPrice,
      };

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });
      const data = await response.json();
      if (
        data.message === "Price updated successfully" ||
        data.message === "Price created successfully"
      ) {
        setNotification({ message: data.message, error: false });
        setOpenModal(false); 
        fetchPrices(currentPage); 
      } else {
        setNotification({ message: data.message, error: false });
      }
    } catch (error) {
      setNotification({ message: "Error saving price", error: true });
    }
  };

  const handleDelete = async (userId) => {
    try {
      const response = await fetch(`http://localhost:3000/api/user-prices`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      });
      const data = await response.json();
      if (data.message === "Price deleted successfully") {
        setNotification({ message: data.message, error: false });
        fetchPrices(currentPage); 
      } else {
        setNotification({ message: data.message, error: true });
      }
    } catch (error) {
      setNotification({ message: "Error deleting price", error: true });
    }
  };

  const renderPrices = () =>
    prices.map((price) => (
      <TableRow key={price.user_id}>
        <TableCell style={{ color: "#b0b0b0" }}>{price.user_id}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{price.USPS_GROUND_15}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{price.USPS_GROUND_70}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{price.USPS_PRIORITY}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{price.USPS_EXPRESS}</TableCell>
        <TableCell>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
          <Tooltip title="Edit Pricing">
            <Button
              onClick={() => handleOpenModal("edit", price)}
              color="primary"
              sx={{ minWidth: "auto", padding: "6px" }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            </Tooltip>
            <Tooltip title="Delete Pricing">
            <Button
              onClick={() => handleDelete(price.user_id)}
              color="error"
              sx={{ minWidth: "auto", padding: "6px" }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    ));

  return (
    <>
     <div style={{ marginTop: "15px", marginLeft: "35px" }}>
    <Breadcrumb breadcrumbItem={[{ name: "Manage" }, { name: "User" }, { name: "Pricing" }]} />
      <WelcomeHeader />
      </div>
    <Box sx={{ padding: 3 }}>
      {notification.message && (
          <Alert
          severity={notification.error ? "error" : "success"}
          sx={{ mb: 2 }}
          onClose={() => setNotification({ message: "", error: false })}
          >
          {notification.message}
        </Alert>
      )}



      <Button
        variant="contained"
        onClick={() => handleOpenModal("create")}
        sx={{ backgroundColor: "gray", color: "#fff", mb: 2 }}
        >
        Add New Price
      </Button>

      <TableStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>User ID</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>USPS Ground 15</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>USPS Ground 70</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>USPS Priority</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>USPS Express</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderPrices()}</TableBody>
        </Table>
      </TableStyled>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalContainer>
          <Typography variant="h6" sx={{ color: "#fff", marginBottom: 2 }}>
            {modalMode === "edit" ? "Edit Price" : "Add New Price"}
          </Typography>

          <TextField
            label="User ID"
            value={currentPrice.user_id}
            onChange={(e) => setCurrentPrice({ ...currentPrice, user_id: e.target.value })}
            fullWidth
            disabled={modalMode === "edit"}
            sx={{
                mb: 2,
                "& .MuiInputBase-input": { color: "#b0b0b0" },
                "& .MuiInputLabel-root": { color: "#b0b0b0" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#b0b0b0" },
            }}
            InputLabelProps={{
                style: { color: "#b0b0b0" },
            }}
            />

          <TextField
            label="USPS Ground 15"
            value={currentPrice.USPS_GROUND_15}
            onChange={(e) => setCurrentPrice({ ...currentPrice, USPS_GROUND_15: e.target.value })}
            fullWidth
            sx={{
                mb: 2,
                "& .MuiInputBase-input": { color: "#b0b0b0" },
                "& .MuiInputLabel-root": { color: "#b0b0b0" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#b0b0b0" },
            }}
            InputLabelProps={{
                style: { color: "#b0b0b0" },
            }}
            />

          <TextField
            label="USPS Ground 70"
            value={currentPrice.USPS_GROUND_70}
            onChange={(e) => setCurrentPrice({ ...currentPrice, USPS_GROUND_70: e.target.value })}
            fullWidth
            sx={{
                mb: 2,
                "& .MuiInputBase-input": { color: "#b0b0b0" },
                "& .MuiInputLabel-root": { color: "#b0b0b0" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#b0b0b0" },
            }}
            InputLabelProps={{
                style: { color: "#b0b0b0" },
            }}
            />

          <TextField
            label="USPS Priority"
            value={currentPrice.USPS_PRIORITY}
            onChange={(e) => setCurrentPrice({ ...currentPrice, USPS_PRIORITY: e.target.value })}
            fullWidth
            sx={{
                mb: 2,
                "& .MuiInputBase-input": { color: "#b0b0b0" },
                "& .MuiInputLabel-root": { color: "#b0b0b0" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#b0b0b0" },
            }}
            InputLabelProps={{
                style: { color: "#b0b0b0" },
            }}
            />

          <TextField
            label="USPS Express"
            value={currentPrice.USPS_EXPRESS}
            onChange={(e) => setCurrentPrice({ ...currentPrice, USPS_EXPRESS: e.target.value })}
            fullWidth
            sx={{
                mb: 2,
                "& .MuiInputBase-input": { color: "#b0b0b0" },
                "& .MuiInputLabel-root": { color: "#b0b0b0" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#b0b0b0" },
            }}
            InputLabelProps={{
                style: { color: "#b0b0b0" },
            }}
            />

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button onClick={() => setOpenModal(false)} color="error">
              Close
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </Box>
        </ModalContainer>
      </Modal>
    </Box>
            </>
  );
};

export default ManageUserPrices;
