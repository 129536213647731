import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";

export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${
          process.env.PUBLIC_URL || "http://localhost:3000"
        }/api/login-user-to-the-app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      const data = await response.json();

      if (response.ok && data.status === "success") {
        localStorage.setItem("token", data.token);

        setAlert(
          <Alert severity="success" onClose={() => setAlert(null)}>
            <AlertTitle>Success</AlertTitle>
            Successfully logged in!
          </Alert>
        );

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setAlert(
          <Alert severity="error" onClose={() => setAlert(null)}>
            <AlertTitle>Error</AlertTitle>
            {data.message || "Login failed. Please try again."}
          </Alert>
        );
      }
    } catch (error) {
      console.error("Login error:", error);
      setAlert(
        <Alert severity="error" onClose={() => setAlert(null)}>
          <AlertTitle>Error</AlertTitle>
          An error occurred. Please try again.
        </Alert>
      );
    }
  };

  return (
    <>
      <Box sx={{ my: 2 }}>{alert}</Box> {/* Display alert above form */}
      <div className="sm:mb-8 mb-6 text-center">
        <div className="sm:text-[40px]/[48px] text-[30px]/[36px] font-medium mb-2">
          Sign In
        </div>
        <span className="text-font-color-100 inline-block">
          Free access to our dashboard.
        </span>
      </div>
      <form onSubmit={handleLogin}>
        <div className="form-control mb-15">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="name@example.com"
            className="form-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-control mb-15">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Enter the password"
              className="form-input !pr-12"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute top-[50%] translate-y-[-50%] right-3 text-font-color-100">
              {showPassword ? <IconEyeOff /> : <IconEye />}
            </button>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between gap-10 sm:mb-30 mb-6">
          <Link
            to="/forgot"
            className="text-primary sm:text-[16px]/[24px] text-[14px]/[20px]">
            Forgot Password?
          </Link>
        </div>
        <button
          type="submit"
          className="btn btn-secondary large w-full uppercase">
          Sign In
        </button>
        <div className="text-center sm:mt-30 mt-6 text-font-color-100">
          <p>Don't have an account yet?</p>
          <Link to="/register" className="text-primary">
            Sign up here
          </Link>
        </div>
      </form>
    </>
  );
}
