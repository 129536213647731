import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Alert,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUndoAlt, faTruck } from "@fortawesome/free-solid-svg-icons";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";


const breadcrumbItem = [
    { name: "Admin" },
    { name: "Manage Order"}
  ];

const TableStyled = styled(TableContainer)({
  marginTop: 20,
  borderRadius: 8,
  color: "#ffffff",
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
});

const PaginationStyled = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "gray",
  },
  "& .Mui-selected": {
    backgroundColor: "#4ecca3",
    color: "#fff",
  },
});

const ManageOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [notification, setNotification] = useState({ message: "", error: false });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const pageSize = 10;

  const token = localStorage.getItem("token");

  const fetchOrders = async (page = currentPage) => {
    setLoading(true);
    try {
      const response = await fetch(`http://localhost:3000/api/admin/orders?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.message === "Orders fetched successfully") {
        setOrders(data.orders);
        setFilteredOrders(data.orders);
        setTotalPages(data.totalPages || 1);
      } else {
        setNotification({ message: "Error fetching orders", error: true });
      }
    } catch (error) {
      setNotification({ message: "Failed to fetch orders", error: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);
    const filtered = orders.filter(
      (order) =>
        order.id.toString().includes(value) ||
        order.user_id.toString().includes(value) ||
        (order.trackId && order.trackId.toString().includes(value)) ||
        (order.transId && order.transId.toString().includes(value)) ||
        (order.price && order.price.toString().includes(value))
    );
    setFilteredOrders(filtered);
    setCurrentPage(1);
  };

  const handleRefund = async (orderId) => {
    try {
      const response = await fetch("http://localhost:3000/api/admin/refund-order", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ order_id: orderId }),
      });
      const data = await response.json();
      if (data.message === "User balance updated successfully for refund") {
        setNotification({ message: "Refund successful", error: false });
        fetchOrders();
      } else {
        setNotification({ message: data.message, error: true });
      }
    } catch (error) {
      setNotification({ message: "Error processing refund", error: true });
    }
  };

  const renderOrders = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const ordersToDisplay = filteredOrders.slice(startIndex, startIndex + pageSize);

    return ordersToDisplay.map((order) => (
      <TableRow key={order.id}>
        <TableCell style={{ color: "#b0b0b0" }}>{order.id}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{order.user_id}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{order.class}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>${order.price}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{order.trackId || "N/A"}</TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{order.transId || "N/A"}</TableCell>
        <TableCell>
          <Box
            sx={{
              display: "inline-block",
              py: "2px",
              px: "8px",
              borderRadius: "4px",
              backgroundColor: order.is_minused === 0 ? "#ffc107" : "#28a745",
              color: "#fff",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {order.is_minused === 0 ? "Not Minused" : "Minused"}
          </Box>
        </TableCell>
        <TableCell style={{ color: "#b0b0b0" }}>{new Date(order.shipmentDate).toLocaleDateString()}</TableCell>
        <TableCell>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            {}
            <Tooltip title="Track Package">
              <Button
                href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${order.trackId}`}
                target="_blank"
                color="success"
                sx={{ minWidth: "auto", padding: "6px" }}
              >
                <FontAwesomeIcon icon={faTruck} />
              </Button>
            </Tooltip>

            {}
            <Tooltip title="Refund Order">
              <Button
                onClick={() => handleRefund(order.id)}
                color="success"
                sx={{ minWidth: "auto", padding: "6px" }}
              >
                <FontAwesomeIcon icon={faUndoAlt} />
              </Button>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
        <div style={{ marginTop: "15px", marginLeft: "35px" }}>

       <Breadcrumb breadcrumbItem={breadcrumbItem} />
       <WelcomeHeader />
       </div>
    <Box sx={{ padding: 3 }}>
      {notification.message && (
        <Alert severity={notification.error ? "error" : "success"} sx={{ mb: 2 }}>
          {notification.message}
        </Alert>
      )}


      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <TextField
          value={searchValue}
          onChange={handleSearch}
          placeholder="Search by ID, User ID, Track ID, Trans ID, Price..."
          variant="outlined"
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "gray",
                borderStyle: "dotted",
              },
              "&:hover fieldset": {
                borderColor: "lightgray",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4ecca3",
              },
              color: "gray",
            },
            "& .MuiInputBase-input": {
              color: "gray",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "gray",
            },
          }}
        />
      </Box>

      <TableStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>ID</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>User ID</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Class</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Price</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Track ID</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Trans ID</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Shipment Date</TableCell>
              <TableCell style={{ color: "#ccc", fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{loading ? <TableRow><TableCell colSpan={9}>Loading...</TableCell></TableRow> : renderOrders()}</TableBody>
        </Table>
      </TableStyled>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <PaginationStyled
          count={totalPages}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
        />
      </Box>
    </Box>
    </>
  );
};

export default ManageOrders;
