import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Grid,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: "20px",
  backgroundColor: "var(--card-color)",
  color: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  maxWidth: "800px",
  margin: "20px auto",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
    margin: "10px",
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ffffff",
  color: "#000000",
  padding: "12px",
  border: "none",
  borderRadius: "50px",
  fontSize: "16px",
  cursor: "pointer",
  width: "100%",
  maxWidth: "200px",
  margin: "20px auto 0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const TableStyled = styled(TableContainer)(({ theme }) => ({
  marginTop: 20,
  borderRadius: 8,
  color: "#ffffff",
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
  [theme.breakpoints.down("sm")]: {
    marginTop: 10,
  },
}));

export default function ManageAddress() {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    message: "",
    error: false,
  });
  const [editData, setEditData] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [formData, setFormData] = useState({
    address_name: "",
    type: "1",
    company: "",
    name: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
  });

  const loadAddresses = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("http://localhost:3000/api/view-addresses", {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
        },
      });
      const data = await response.json();
      if (data.status === "success") {
        setAddresses(data.data);
      } else {
        setNotification({ message: `Error: ${data.message}`, error: true });
      }
    } catch (error) {
      setNotification({ message: "Failed to load addresses", error: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAddresses();
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const endpoint = editData
        ? "http://localhost:3000/api/update-address"
        : "http://localhost:3000/api/add-address";
      const method = editData ? "PUT" : "POST";

      const response = await fetch(endpoint, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
        },
        body: JSON.stringify(
          editData ? { ...formData, address_id: editData.id } : formData
        ),
      });
      const data = await response.json();
      if (data.status === "success") {
        setNotification({
          message: editData
            ? "Address updated successfully"
            : "Address added successfully",
          error: false,
        });
        loadAddresses();
        setFormData({
          address_name: "",
          type: "1",
          company: "",
          name: "",
          address1: "",
          address2: "",
          address3: "",
          city: "",
          state: "",
          zipcode: "",
          phone: "",
        });
        setEditData(null);
      } else {
        setNotification({ message: `Error: ${data.message}`, error: true });
      }
    } catch (error) {
      setNotification({ message: "An error occurred", error: true });
    }
  };

  const handleEdit = (address) => {
    setEditData(address);
    setFormData(address);
  };

  const handleDeleteClick = (id) => {
    setDeleteDialogOpen(true);
    setDeleteId(id);
  };

  const handleDeleteConfirm = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("http://localhost:3000/api/delete-address", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
        },
        body: JSON.stringify({ address_id: deleteId }),
      });
      const data = await response.json();
      if (data.status === "success") {
        setNotification({
          message: "Address deleted successfully",
          error: false,
        });
        loadAddresses();
      } else {
        setNotification({ message: `Error: ${data.message}`, error: true });
      }
    } catch (error) {
      setNotification({ message: "An error occurred", error: true });
    } finally {
      setDeleteDialogOpen(false);
      setDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteId(null);
  };

  return (
    <div style={{ marginTop: "15px", marginLeft: "35px" }}>
      <Breadcrumb breadcrumbItem={[{ name: "Manage" }, { name: "Address" }]} />
      <WelcomeHeader />
      <Box sx={{ padding: 3 }}>
        {notification.message && (
          <Alert
            severity={notification.error ? "error" : "success"}
            sx={{ mb: 2 }}
          >
            {notification.message}
          </Alert>
        )}
        <FormContainer>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ fontWeight: "bold", color: "gray" }}
          >
            {editData ? "Edit Address" : "Add Address"}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address Name"
                  name="address_name"
                  fullWidth
                  required
                  value={formData.address_name}
                  onChange={handleFormChange}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Type"
                  name="type"
                  select
                  SelectProps={{ native: true }}
                  fullWidth
                  required
                  value={formData.type}
                  onChange={handleFormChange}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                >
                  <option value="1">From</option>
                  <option value="0">To</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Company"
                  name="company"
                  fullWidth
                  value={formData.company}
                  onChange={handleFormChange}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Full Name"
                  name="name"
                  fullWidth
                  required
                  value={formData.name}
                  onChange={handleFormChange}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address Line 1"
                  name="address1"
                  fullWidth
                  required
                  value={formData.address1}
                  onChange={handleFormChange}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address Line 2"
                  name="address2"
                  fullWidth
                  value={formData.address2}
                  onChange={handleFormChange}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  name="city"
                  fullWidth
                  required
                  value={formData.city}
                  onChange={handleFormChange}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="State"
                  name="state"
                  fullWidth
                  required
                  value={formData.state}
                  onChange={handleFormChange}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Zip Code"
                  name="zipcode"
                  fullWidth
                  required
                  value={formData.zipcode}
                  onChange={handleFormChange}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone"
                  name="phone"
                  fullWidth
                  required
                  value={formData.phone}
                  onChange={handleFormChange}
                  sx={{ input: { color: "gray" }, label: { color: "gray" } }}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <SubmitButton type="submit">
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : editData ? (
                    "Update Address"
                  ) : (
                    "Add Address"
                  )}
                </SubmitButton>
              </Grid>
            </Grid>
          </form>
        </FormContainer>
        <TableStyled>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                  #
                </TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                  Address Name
                </TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                  Type
                </TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                  Address 1
                </TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                  Address 2
                </TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addresses.map((address, index) => (
                <TableRow key={address.id}>
                  <TableCell style={{ color: "#b0b0b0" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell style={{ color: "#b0b0b0" }}>
                    {address.address_name}
                  </TableCell>
                  <TableCell style={{ color: "#b0b0b0" }}>
                    {address.type === "1" ? "From" : "To"}
                  </TableCell>
                  <TableCell style={{ color: "#b0b0b0" }}>
                    {address.address1}
                  </TableCell>
                  <TableCell style={{ color: "#b0b0b0" }}>
                    {address.address2 || "N/A"}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <Button
                        onClick={() => handleEdit(address)}
                        color="primary"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button
                        onClick={() => handleDeleteClick(address.id)}
                        color="secondary"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableStyled>
      </Box>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this address?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
