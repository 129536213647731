import React from 'react';
import { Link } from 'react-router-dom';

export default function Breadcrumb({ breadcrumbItem }) {
  return (
    <nav className="mb-4">
      <ul className="flex flex-wrap items-center gap-x-2 gap-y-1 text-font-color">
        {/* Home Link */}
        <li>
          <Link
            to="/"
            className="text-primary font-semibold hover:underline sm:text-[16px]/[24px] text-[14px]/[20px]"
          >
            Home
          </Link>
        </li>
        {/* Dynamic Breadcrumb Links */}
        {breadcrumbItem?.map((item, key) => (
          <li
            key={key}
            className="flex items-center gap-2 sm:text-[16px]/[24px] text-[14px]/[20px]"
          >
            <span className="text-gray-400">/</span>
            {item.link ? (
              <Link
                to={item.url}
                className="text-primary font-medium hover:underline transition-all duration-200"
              >
                {item.link}
              </Link>
            ) : (
              <span className="text-gray-500">{item.name}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}
