import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Tooltip,
  TextField,
  MenuItem,
  Skeleton
} from "@mui/material";
import { styled } from "@mui/material/styles";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import bitcoinIcon from "../../assets/images/bitcoin.png";
import litecoinIcon from "../../assets/images/litecoin.png";
import solanaIcon from "../../assets/images/solana.png";
import ethereumIcon from "../../assets/images/ethereum.png";
import paypalIcon from "../../assets/images/paypal.png";
import cashappIcon from "../../assets/images/cashapp.png";

const TableStyled = styled(TableContainer)({
  marginTop: 20,
  borderRadius: 8,
  border: "2px dotted var(--border-color)",
  backgroundColor: "var(--card-color)",
  color: "#ffffff",
});

const PaginationStyled = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "gray",
  },
  "& .Mui-selected": {
    backgroundColor: "#4ecca3",
    color: "#fff",
  },
});

const Filters = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "10px",
  marginBottom: "20px",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    gridTemplateColumns: "1fr",
  },
});

const FilterInput = styled(TextField)({
  flex: "1 1 calc(25% - 10px)",
  minWidth: "150px",
  "& .MuiOutlinedInput-root": {
    borderColor: "gray",
    color: "gray",
  },
  "& .MuiInputLabel-root": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root:hover fieldset": {
    borderColor: "lightgray",
  },
  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
    borderColor: "white",
  },
  "@media (max-width: 768px)": {
    flex: "1 1 100%",
  },
});

const paymentMethodsImages = {
  paypal: paypalIcon,
  cashapp: cashappIcon,
  bitcoin: bitcoinIcon,
  ethereum: ethereumIcon,
  litecoin: litecoinIcon,
  solana: solanaIcon,
};

export default function DepositsPage() {
  const [deposits, setDeposits] = useState([]);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchFilters, setSearchFilters] = useState({
    paymentMethod: "",
    paymentId: "",
    status: "",
    createdAt: "",
    amount: "",
  });

  const itemsPerPage = 10;

  useEffect(() => {
    loadDeposits();
  }, []);

  useEffect(() => {
    applySearchFilters();
  }, [searchFilters, deposits]);

  const loadDeposits = async () => {
    setLoading(true);
    try {
        const token = localStorage.getItem("token");
        const response = await fetch("http://localhost:3000/api/get-all-deposits", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        
      const result = await response.json();
      if (result.message === "Deposits fetched successfully") {
        setDeposits(result.deposits);
        setFilteredDeposits(result.deposits);
        setTotalPages(result.totalPages);
      }
    } catch (error) {
      console.error("Error fetching deposits:", error);
    } finally {
      setLoading(false);
    }
  };

  const applySearchFilters = () => {
    const filtered = deposits.filter((deposit) => {
      const matchesPaymentMethod = searchFilters.paymentMethod
        ? deposit.payment_method === searchFilters.paymentMethod
        : true;
      const matchesPaymentId = searchFilters.paymentId
        ? deposit.payment_id?.toLowerCase().includes(searchFilters.paymentId.toLowerCase())
        : true;
      const matchesStatus =
        searchFilters.status === "completed"
          ? deposit.status === 1
          : searchFilters.status === "pending"
          ? deposit.status === 0
          : true;
      const matchesCreatedAt = searchFilters.createdAt
        ? new Date(deposit.created_at).toLocaleDateString("en-US") ===
          new Date(searchFilters.createdAt).toLocaleDateString("en-US")
        : true;
      const matchesAmount = searchFilters.amount
        ? deposit.amount === searchFilters.amount
        : true;

      return (
        matchesPaymentMethod &&
        matchesPaymentId &&
        matchesStatus &&
        matchesCreatedAt &&
        matchesAmount
      );
    });

    setFilteredDeposits(filtered);
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const breadcrumbItem = [{ name: "Deposit History" }];

  return (
    <>
      <div style={{ marginTop: "15px", marginLeft: "35px" }}>
        <Breadcrumb breadcrumbItem={breadcrumbItem} />
        <WelcomeHeader />
      </div>
      <Box sx={{ padding: 3 }}>
        <Filters>
          <FilterInput
            label="Payment Method"
            select
            variant="outlined"
            size="small"
            name="paymentMethod"
            value={searchFilters.paymentMethod}
            onChange={handleSearchChange}
            sx={{ backgroundColor: "card-body", color: "#fff" }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="paypal">PayPal</MenuItem>
            <MenuItem value="cashapp">CashApp</MenuItem>
            <MenuItem value="bitcoin">Bitcoin</MenuItem>
            <MenuItem value="ethereum">Ethereum</MenuItem>
            <MenuItem value="litecoin">Litecoin</MenuItem>
            <MenuItem value="solana">Solana</MenuItem>
          </FilterInput>
          <FilterInput
            label="Payment ID"
            variant="outlined"
            size="small"
            name="paymentId"
            value={searchFilters.paymentId}
            onChange={handleSearchChange}
            sx={{ backgroundColor: "card-body", color: "#fff" }}
          />
          <FilterInput
            label="Created At"
            type="date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            name="createdAt"
            value={searchFilters.createdAt}
            onChange={handleSearchChange}
            sx={{ backgroundColor: "card-body", color: "#fff" }}
          />
          <FilterInput
            label="Amount"
            variant="outlined"
            size="small"
            name="amount"
            value={searchFilters.amount}
            onChange={handleSearchChange}
            sx={{ backgroundColor: "card-body", color: "#fff" }}
          />
          <FilterInput
            label="Status"
            select
            variant="outlined"
            size="small"
            name="status"
            value={searchFilters.status}
            onChange={handleSearchChange}
            sx={{ backgroundColor: "card-body", color: "#fff" }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </FilterInput>
        </Filters>

        <TableStyled component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>ID</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Method</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Amount</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Payment ID</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from(new Array(itemsPerPage)).map((_, index) => (
                    <TableRow key={index}>
                      {Array.from(new Array(6)).map((__, i) => (
                        <TableCell key={i}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : filteredDeposits.map((deposit) => (
                    <TableRow key={deposit.id}>
                      <TableCell style={{ color: "#b0b0b0" }}>{deposit.id}</TableCell>
                      <TableCell>
                        <img
                          src={paymentMethodsImages[deposit.payment_method]}
                          alt={deposit.payment_method}
                          style={{ height: "30px" }}
                        />
                      </TableCell>
                      <TableCell style={{ color: "#b0b0b0" }}>${deposit.amount}</TableCell>
                      <TableCell style={{ color: "#b0b0b0" }}>{deposit.payment_id}</TableCell>
                      <TableCell>
                        <span
                          style={{
                            backgroundColor: deposit.status === 1 ? "green" : "orange",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          {deposit.status === 1 ? "Completed" : "Pending"}
                        </span>
                      </TableCell>
                      <TableCell style={{ color: "#b0b0b0" }}>
                        {new Date(deposit.created_at).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableStyled>
        <PaginationStyled
          count={totalPages}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
          sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
        />
      </Box>
    </>
  );
}
