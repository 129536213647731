import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Alert,
  Switch,
  FormControlLabel,
  Typography,
  IconButton,
  Box,
  styled,
  Checkbox,
} from "@mui/material";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { LoadScriptNext , Autocomplete } from "@react-google-maps/api";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Loop } from "@mui/icons-material";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useNavigate } from "react-router-dom";

const REACT_APP_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const addressAbbreviations = {
  Road: "Rd",
  Street: "St",
  Avenue: "Ave",
  Boulevard: "Blvd",
  Drive: "Dr",
  Lane: "Ln",
  Court: "Ct",
  Square: "Sq",
  Place: "Pl",
  Terrace: "Ter",
  Parkway: "Pkwy",
  Highway: "Hwy",
  Circle: "Cir",
  Apartment: "Apt",
  Suite: "Ste",
  North: "N",
  East: "E",
  South: "S",
  West: "W",
  "North East": "NE",
  "North West": "NW",
  "South East": "SE",
  "South West": "SW",
  "north east": "NE",
  "north west": "NW",
  "south east": "SE",
  "south west": "SW",
  Ridge: "Rdg",
  Trail: "Trl",
  Floor: "FL",
  NorthEast: "NE",
  northeast: "NE",
  northwest: "NW",
  southeast: "SE",
  southwest: "SW",
};

const stateOptions = [
  { value: "", label: "Select State" },
  { value: "AL", label: "Alabama (AL)" },
  { value: "AK", label: "Alaska (AK)" },
  { value: "AZ", label: "Arizona (AZ)" },
  { value: "AR", label: "Arkansas (AR)" },
  { value: "CA", label: "California (CA)" },
  { value: "CO", label: "Colorado (CO)" },
  { value: "CT", label: "Connecticut (CT)" },
  { value: "DC", label: "District Of Columbia (DC)" },
  { value: "DE", label: "Delaware (DE)" },
  { value: "FL", label: "Florida (FL)" },
  { value: "GA", label: "Georgia (GA)" },
  { value: "HI", label: "Hawaii (HI)" },
  { value: "ID", label: "Idaho (ID)" },
  { value: "IL", label: "Illinois (IL)" },
  { value: "IN", label: "Indiana (IN)" },
  { value: "IA", label: "Iowa (IA)" },
  { value: "KS", label: "Kansas (KS)" },
  { value: "KY", label: "Kentucky (KY)" },
  { value: "LA", label: "Louisiana (LA)" },
  { value: "ME", label: "Maine (ME)" },
  { value: "MD", label: "Maryland (MD)" },
  { value: "MA", label: "Massachusetts (MA)" },
  { value: "MI", label: "Michigan (MI)" },
  { value: "MN", label: "Minnesota (MN)" },
  { value: "MS", label: "Mississippi (MS)" },
  { value: "MO", label: "Missouri (MO)" },
  { value: "MT", label: "Montana (MT)" },
  { value: "NE", label: "Nebraska (NE)" },
  { value: "NV", label: "Nevada (NV)" },
  { value: "NH", label: "New Hampshire (NH)" },
  { value: "NJ", label: "New Jersey (NJ)" },
  { value: "NM", label: "New Mexico (NM)" },
  { value: "NY", label: "New York (NY)" },
  { value: "NC", label: "North Carolina (NC)" },
  { value: "ND", label: "North Dakota (ND)" },
  { value: "OH", label: "Ohio (OH)" },
  { value: "OK", label: "Oklahoma (OK)" },
  { value: "OR", label: "Oregon (OR)" },
  { value: "PA", label: "Pennsylvania (PA)" },
  { value: "PR", label: "Puerto Rico (PR)" },
  { value: "RI", label: "Rhode Island (RI)" },
  { value: "SC", label: "South Carolina (SC)" },
  { value: "SD", label: "South Dakota (SD)" },
  { value: "TN", label: "Tennessee (TN)" },
  { value: "TX", label: "Texas (TX)" },
  { value: "UT", label: "Utah (UT)" },
  { value: "VT", label: "Vermont (VT)" },
  { value: "VA", label: "Virginia (VA)" },
  { value: "WA", label: "Washington (WA)" },
  { value: "WV", label: "West Virginia (WV)" },
  { value: "WI", label: "Wisconsin (WI)" },
  { value: "WY", label: "Wyoming (WY)" },
];

const customTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          width: "calc(100% + 8px)",
          border: "1px solid gray",
        },
        input: {
          color: "#ffffff",
          "&::placeholder": {
            color: "gray",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "gray",
        },
      },
    },
  },
});

const StyledTextField = styled(TextField)(({ isDarkTheme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  "& .MuiInputBase-input": {
    color: isDarkTheme ? "#ffffff" : "gray",
  },
  "& .MuiFormLabel-root": {
    color: isDarkTheme ? "#ffffff" : "gray",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: isDarkTheme ? "#ffffff" : "gray",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ isDarkTheme }) => ({
  color: isDarkTheme ? "#ffffff" : "gray",
  "&.Mui-selected": {
    backgroundColor: isDarkTheme
      ? "rgba(255, 255, 255, 0.2)"
      : "rgba(128, 128, 128, 0.2)",
  },
}));

const bannedZipCodes = [
  "11953",
  "11703",
  "92683",
  "14213",
  "90601",
  "91714",
  "91715",
  "91716",
  "91732",
  "91744",
  "91745",
  "91746",
  "91748",
  "91749",
  "91765",
  "91789",
  "3060",
  "3061",
  "3062",
  "3063",
  "3064",
  "67052",
  "67101",
  "67106",
  "67201",
  "67202",
  "67203",
  "67204",
  "67205",
  "67206",
  "67207",
  "67208",
  "67209",
  "67210",
  "67211",
  "67212",
  "67213",
  "67214",
  "67215",
  "67216",
  "67217",
  "67218",
  "67219",
  "67220",
  "67223",
  "67226",
  "67228",
  "67230",
  "67232",
  "67235",
  "67260",
  "67275",
  "67276",
  "67277",
  "67278",
  "95655",
  "95670",
  "95741",
  "95742",
  "95827",
  "95661",
  "95678",
  "91709",
  "11040",
  "95746",
  "95747",
  "95677",
  "95765",
  "94016",
  "94102",
  "94103",
  "94104",
  "94105",
  "94107",
  "94108",
  "94109",
  "94110",
  "94111",
  "94112",
  "94114",
  "94115",
  "94116",
  "94117",
  "94118",
  "94119",
  "94120",
  "94121",
  "94122",
  "94123",
  "94124",
  "94125",
  "94126",
  "94127",
  "94129",
  "94130",
  "94131",
  "94132",
  "94133",
  "94134",
  "94137",
  "94139",
  "94140",
  "94141",
  "94142",
  "94143",
  "94144",
  "94145",
  "94146",
  "94147",
  "94151",
  "94158",
  "94159",
  "94160",
  "94161",
  "94163",
  "94164",
  "94172",
  "94177",
  "94188",
  "48430",
  "48340",
  "48341",
  "48342",
  "48343",
  "18001",
  "18002",
  "18003",
  "11714",
  "18010",
  "01174",
  "18011",
  "18012",
  "18013",
  "18014",
  "18015",
  "18016",
  "18017",
  "18018",
  "18020",
  "18025",
  "18030",
  "18031",
  "18032",
  "18034",
  "18035",
  "18036",
  "18037",
  "18038",
  "18039",
  "18040",
  "18041",
  "18042",
  "18043",
  "18044",
  "18045",
  "18046",
  "18049",
  "18050",
  "18051",
  "18052",
  "18053",
  "18054",
  "18055",
  "18056",
  "18058",
  "18059",
  "18060",
  "18062",
  "18063",
  "18064",
  "18065",
  "18066",
  "18067",
  "18068",
  "18069",
  "18070",
  "18071",
  "18072",
  "18073",
  "18074",
  "18076",
  "18077",
  "18078",
  "18079",
  "18080",
  "18081",
  "18083",
  "18084",
  "18085",
  "18086",
  "18087",
  "18088",
  "18091",
  "18092",
  "18098",
  "18099",
  "18101",
  "18102",
  "18103",
  "18104",
  "18105",
  "18106",
  "18109",
  "18175",
  "18195",
  "18201",
  "18202",
  "18210",
  "18211",
  "18212",
  "18214",
  "18216",
  "18218",
  "18219",
  "18220",
  "18221",
  "18222",
  "18223",
  "18224",
  "18225",
  "18229",
  "18230",
  "18231",
  "18232",
  "18234",
  "18235",
  "18237",
  "18239",
  "18240",
  "18241",
  "18242",
  "18244",
  "18245",
  "18246",
  "18247",
  "18248",
  "18249",
  "18250",
  "18251",
  "18252",
  "18254",
  "18255",
  "18256",
  "18301",
  "18302",
  "18320",
  "18321",
  "18322",
  "18323",
  "18324",
  "18325",
  "18326",
  "18327",
  "18328",
  "18330",
  "18331",
  "18332",
  "18333",
  "18334",
  "18335",
  "18336",
  "18337",
  "18340",
  "18341",
  "18342",
  "18343",
  "18344",
  "18346",
  "18347",
  "18348",
  "18349",
  "18350",
  "18351",
  "18352",
  "18353",
  "18354",
  "18355",
  "18356",
  "18357",
  "18360",
  "18370",
  "18371",
  "18372",
  "18403",
  "18405",
  "18407",
  "18410",
  "18411",
  "18413",
  "18414",
  "18415",
  "18416",
  "18417",
  "18419",
  "18420",
  "18421",
  "18424",
  "18425",
  "18426",
  "18427",
  "18428",
  "18430",
  "18431",
  "18433",
  "18434",
  "18435",
  "18436",
  "18437",
  "18438",
  "18439",
  "18440",
  "18441",
  "18443",
  "18444",
  "18445",
  "18446",
  "18447",
  "18448",
  "18449",
  "18451",
  "18452",
  "18453",
  "18454",
  "18455",
  "18456",
  "18457",
  "18458",
  "18459",
  "18460",
  "18461",
  "18462",
  "18463",
  "18464",
  "18465",
  "18466",
  "18469",
  "18470",
  "18471",
  "18472",
  "18473",
  "18501",
  "18502",
  "18503",
  "18504",
  "18505",
  "18507",
  "18508",
  "18509",
  "18510",
  "18512",
  "18515",
  "18517",
  "18518",
  "18519",
  "18522",
  "18540",
  "18577",
  "18601",
  "18602",
  "18603",
  "18610",
  "18611",
  "18612",
  "18614",
  "18615",
  "18616",
  "18617",
  "18618",
  "18619",
  "18621",
  "18622",
  "18623",
  "18624",
  "18625",
  "18626",
  "18627",
  "18628",
  "18629",
  "18630",
  "18631",
  "18632",
  "18634",
  "18635",
  "18636",
  "18640",
  "18641",
  "18642",
  "18643",
  "18644",
  "18651",
  "18653",
  "18654",
  "18655",
  "18656",
  "18657",
  "18660",
  "18661",
  "18690",
  "18701",
  "18702",
  "18704",
  "18705",
  "18706",
  "18707",
  "18708",
  "18709",
  "18710",
  "18711",
  "18762",
  "18764",
  "18765",
  "18766",
  "18767",
  "18769",
  "18773",
  "18801",
  "18810",
  "18812",
  "18813",
  "18814",
  "18815",
  "18816",
  "18817",
  "18818",
  "18820",
  "18821",
  "18822",
  "18823",
  "18824",
  "18825",
  "18826",
  "18827",
  "18828",
  "18829",
  "18830",
  "18831",
  "18832",
  "18833",
  "18834",
  "18837",
  "18840",
  "18842",
  "18843",
  "18844",
  "18845",
  "18846",
  "18847",
  "18848",
  "18850",
  "18851",
  "18853",
  "18854",
  "18901",
  "18902",
  "18910",
  "18911",
  "18912",
  "18913",
  "18914",
  "18915",
  "18916",
  "18917",
  "18918",
  "18920",
  "18921",
  "18922",
  "18923",
  "18924",
  "18925",
  "18926",
  "18927",
  "18928",
  "18929",
  "18930",
  "18931",
  "18932",
  "18933",
  "18934",
  "18935",
  "18936",
  "18938",
  "18940",
  "18942",
  "18943",
  "18944",
  "18946",
  "18947",
  "18949",
  "18950",
  "18951",
  "18953",
  "18954",
  "18955",
  "18956",
  "18957",
  "18958",
  "18960",
  "18962",
  "18963",
  "18964",
  "18966",
  "18968",
  "18969",
  "18970",
  "18971",
  "18972",
  "18974",
  "18976",
  "18977",
  "18979",
  "18980",
  "18981",
  "18991",
  "19001",
  "19002",
  "19003",
  "19004",
  "19006",
  "19007",
  "19008",
  "19009",
  "19010",
  "19012",
  "19013",
  "19014",
  "19015",
  "19016",
  "19017",
  "19018",
  "19019",
  "19020",
  "19021",
  "19022",
  "19023",
  "19025",
  "19026",
  "19027",
  "19028",
  "19029",
  "19030",
  "19031",
  "19032",
  "19033",
  "19034",
  "19035",
  "19036",
  "19037",
  "19038",
  "19039",
  "19040",
  "19041",
  "19043",
  "19044",
  "19046",
  "19047",
  "19048",
  "19049",
  "19050",
  "19052",
  "19053",
  "19054",
  "19055",
  "19056",
  "19057",
  "19058",
  "19060",
  "19061",
  "19063",
  "19064",
  "19065",
  "19066",
  "19067",
  "19070",
  "19072",
  "19073",
  "19074",
  "19075",
  "19076",
  "19078",
  "19079",
  "19080",
  "19081",
  "19082",
  "19083",
  "19085",
  "19086",
  "19087",
  "19088",
  "19089",
  "19090",
  "19091",
  "19092",
  "19093",
  "19094",
  "19095",
  "19096",
  "19098",
  "19099",
  "19101",
  "19102",
  "19103",
  "19104",
  "19105",
  "19106",
  "19107",
  "19108",
  "19109",
  "19110",
  "19111",
  "19112",
  "19113",
  "19114",
  "19115",
  "19116",
  "19118",
  "19119",
  "19120",
  "19121",
  "19122",
  "19123",
  "19124",
  "19125",
  "19126",
  "19127",
  "19128",
  "19129",
  "19130",
  "19131",
  "19132",
  "19133",
  "19134",
  "19135",
  "19136",
  "19137",
  "19138",
  "19139",
  "19140",
];

const LabelCreation = () => {
  const navigate = useNavigate();
  const [isDarkTheme, setIsDarkTheme] = useState(
    document.body.getAttribute("data-theme") === "dark"
  );

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setIsDarkTheme(document.body.getAttribute("data-theme") === "dark");
    });
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["data-theme"],
    });

    return () => observer.disconnect();
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");
  const [fromAddresses, setFromAddresses] = useState([]);
  const [toAddresses, setToAddresses] = useState([]);
  const [saveFromAddress, setSaveFromAddress] = useState(false);
  const [saveToAddress, setSaveToAddress] = useState(false);
  const [fromData, setFromData] = useState({
    name: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [toData, setToData] = useState({
    name: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [packageData, setPackageData] = useState({
    length: "",
    width: "",
    height: "",
    weight: "",
  });
  const [serviceCode, setServiceCode] = useState("");
  const [shipmentDate, setShipmentDate] = useState(null);
  const [price, setPrice] = useState(null);
  const [hazardous, setHazardous] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isLoading, setIsLoading] = useState(false);
  const fromAutocompleteRef = useRef(null);
  const toAutocompleteRef = useRef(null);
  const [fromPhone, setFromPhone] = useState("");
  const [toPhone, setToPhone] = useState("");
  const [template, setTemplate] = useState("BASIC");

  const handleTemplateChange = (event) => {
    setTemplate(event.target.value);
  };

  const handleSaveAddress = async (type) => {
    try {
      const addressData = type === 1 ? fromData : toData;
      const phone = type === 1 ? fromPhone : toPhone;

      const addressPayload = {
        address_name: addressData.name || "Unnamed Address",
        type,
        company: addressData.company || "",
        name: addressData.name || "Unnamed Name",
        address1: addressData.address || "Unnamed Address",
        address2: addressData.address2 || "",
        address3: " ",
        city: addressData.city || "Unnamed City",
        state: addressData.state || "Unnamed State",
        zipcode: addressData.zip || "00000",
        country: addressData.country || "Unknown Country",
        phone: phone || "0000000000",
        created_at: new Date().toISOString().slice(0, 19).replace("T", " "),
        updated_at: new Date().toISOString().slice(0, 19).replace("T", " "),
      };

      await axios.post(
        "http://localhost:3000/api/add-address",
        addressPayload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
          },
        }
      );

      console.log(`Address saved with type ${type}`);
    } catch (error) {
      console.error("Error saving address:", error);
    }
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          "http://localhost:3000/api/view-addresses",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
            },
          }
        );
        const addresses = response.data.data;
        setFromAddresses(addresses.filter((address) => address.type === 1));
        setToAddresses(addresses.filter((address) => address.type === 0));
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };
    fetchAddresses();
  }, []);

  const handleFromSelect = (e) => {
    const selectedId = e.target.value;
    const selectedAddress = fromAddresses.find(
      (addr) => addr.id === selectedId
    );
    if (selectedAddress) {
      setFromData({
        name: selectedAddress.name,
        address: selectedAddress.address1,
        address2: selectedAddress.address2,
        city: selectedAddress.city,
        state: selectedAddress.state,
        zip: selectedAddress.zipcode,
      });
      setFromPhone(selectedAddress.phone);
    }
  };

  const handleToSelect = (e) => {
    const selectedId = e.target.value;
    const selectedAddress = toAddresses.find((addr) => addr.id === selectedId);
    if (selectedAddress) {
      setToData({
        name: selectedAddress.name,
        address: selectedAddress.address1,
        address2: selectedAddress.address2,
        city: selectedAddress.city,
        state: selectedAddress.state,
        zip: selectedAddress.zipcode,
      });
      setToPhone(selectedAddress.phone);
    }
  };

  const generateRandomPhoneNumber = () => {
    const areaCode = Math.floor(100 + Math.random() * 900);
    const centralOfficeCode = Math.floor(100 + Math.random() * 900);
    const lineNumber = Math.floor(1000 + Math.random() * 9000);
    return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
  };

  const handleGenerateFromPhone = () => {
    setFromPhone(generateRandomPhoneNumber());
  };

  const handleGenerateToPhone = () => {
    setToPhone(generateRandomPhoneNumber());
  };

  useEffect(() => {
    setFromPhone(generateRandomPhoneNumber());
    setToPhone(generateRandomPhoneNumber());
  }, []);

  const handleDateChange = (newDate) => {
    setShipmentDate(newDate);
  };

  const abbreviateAddress = (address) => {
    Object.keys(addressAbbreviations).forEach((fullWord) => {
      const abbreviation = addressAbbreviations[fullWord];
      const regex = new RegExp(`\\b${fullWord}\\b`, "gi");
      address = address.replace(regex, abbreviation);
    });
    return address;
  };

  const handlePlaceSelect = (type) => {
    const autocomplete =
      type === "from" ? fromAutocompleteRef.current : toAutocompleteRef.current;
    const place = autocomplete.getPlace();

    const mainAddress = place.name || "";
    const components = place.address_components;
    const city =
      components.find((comp) => comp.types.includes("locality"))?.long_name ||
      "";
    const state =
      components.find((comp) =>
        comp.types.includes("administrative_area_level_1")
      )?.short_name || "";
    const zip =
      components.find((comp) => comp.types.includes("postal_code"))
        ?.long_name || "";

    const data = { address: abbreviateAddress(mainAddress), city, state, zip };

    if (type === "from") {
      setFromData((prev) => ({ ...prev, ...data }));
    } else {
      setToData((prev) => ({ ...prev, ...data }));
    }
  };

  const validateRequiredFields = () => {
    return (
      fromData.name &&
      fromData.address &&
      fromData.city &&
      fromData.state &&
      fromData.zip &&
      toData.name &&
      toData.address &&
      toData.city &&
      toData.state &&
      toData.zip &&
      packageData.weight &&
      serviceCode &&
      shipmentDate
    );
  };

  const fetchPrice = async (classType) => {
    const url = `${
      process.env.REACT_APP_PUBLIC_URL || "http://localhost:3000"
    }/api/get-price/${classType}`;
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
        },
      });
      const data = await response.json();
      setPrice(data.price || "Price not available");
    } catch (error) {
      setNotification({ message: "Error fetching price", type: "error" });
    }
  };

  const handleServiceCodeChange = (event) => {
    const selectedServiceCode = event.target.value;
    setServiceCode(selectedServiceCode);
    fetchPrice(selectedServiceCode);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (saveFromAddress) await handleSaveAddress(1);
      if (saveToAddress) await handleSaveAddress(0);
      const url = `${
        process.env.REACT_APP_PUBLIC_URL || "http://localhost:3000"
      }/api/create-with-direct-data`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "x-api-key": "n79Mo7S8alF13IinoKCUmfKupq99ZdyX",
        },
        body: JSON.stringify({
          fromName: fromData.name,
          fromAddress: fromData.address,
          fromAddress2: fromData.address2 || "",
          fromCity: fromData.city,
          fromState: fromData.state,
          fromZip: fromData.zip,
          fromCompany: "",
          fromPhone: fromPhone || "",
          fromCountry: "",

          toName: toData.name,
          toAddress: toData.address,
          toAddress2: toData.address2 || "",
          toCity: toData.city,
          toState: toData.state,
          toZip: toData.zip,
          toCompany: "",
          toPhone: toPhone || "",
          toCountry: "",

          length: packageData.length,
          width: packageData.width,
          height: packageData.height,
          weight: packageData.weight,
          description: "",

          class: serviceCode,
          isContainsHazardous: hazardous,
          shipmentDate: shipmentDate
            ? shipmentDate.toISOString()
            : new Date().toISOString(),
          template: template,
        }),
      });

      const result = await response.json();
      setNotification({
        message: result.message || "Label created successfully",
        type: response.ok ? "success" : "error",
      });

      if (response.ok) {
        setTimeout(() => {
          navigate("/history");
        }, 1000);
      }
    } catch (error) {
      setNotification({ message: "Error creating label", type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleNumericChange = (e, field, state, setState) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setState((prev) => ({ ...prev, [field]: value }));
  };

  const renderRequiredField = (label) => (
    <Typography
      variant="body1"
      color="error"
      component="span"
      style={{ marginLeft: "5px" }}>
      * {label}
    </Typography>
  );

  const responsiveStyle = isMobile
    ? { width: "100%", margin: "0" }
    : { flex: "1", padding: "20px", borderRadius: "10px" };

  const breadcrumbItem = [{ name: "Create Label" }];

  return (
    <>
      <div style={{ marginTop: "15px", marginLeft: "35px" }}>
        <Breadcrumb breadcrumbItem={breadcrumbItem} />
        <WelcomeHeader />
      </div>

      <LoadScriptNext
        googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places"]}
        loadingElement={<CircularProgress />}>
        <div style={{ padding: "20px" }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
            <div
              className="card border border-dashed border-border-color"
              style={{
                width: "100%",
                maxWidth: "450px",
                margin: "0 auto",
                borderRadius: "15px",
                padding: "20px",
                border: "1px dashed gray"
              }}>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                style={{
                  color: "font-color",
                  fontWeight: "bold",
                }}>
                From Address
              </Typography>

              <StyledTextField
                label="Select Sender"
                fullWidth
                select
                variant="outlined"
                margin="normal"
                onChange={handleFromSelect}
                isDarkTheme={isDarkTheme}
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}>
                {fromAddresses.map((address) => (
                  <MenuItem key={address.id} value={address.id}>
                    {address.address_name}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                label="From Name"
                fullWidth
                variant="outlined"
                margin="normal"
                isDarkTheme={isDarkTheme}
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                value={fromData.name}
                onChange={(e) =>
                  setFromData((prev) => ({ ...prev, name: e.target.value }))
                }
                required
              />

              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <StyledTextField
                  label="From Phone"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={fromPhone}
                  onChange={(e) => setFromPhone(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                    "& label": { color: "gray" },
                    "& input": { color: "#ffffff" },
                  }}
                />
                <IconButton color="primary" onClick={handleGenerateFromPhone}>
                  <Loop />
                </IconButton>
              </div>
              <Autocomplete
                onLoad={(autocomplete) => {
                  fromAutocompleteRef.current = autocomplete;
                }}
                onPlaceChanged={() => handlePlaceSelect("from")}
                options={{
                  componentRestrictions: { country: "us" },
                  types: ["address"],
                }}>
                <StyledTextField
                  label="From Address"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={fromData.address}
                  onChange={(e) =>
                    setFromData((prev) => ({
                      ...prev,
                      address: abbreviateAddress(e.target.value),
                    }))
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                    "& label": { color: "gray" },
                    "& input": { color: "#ffffff" },
                  }}
                  required
                />
              </Autocomplete>
              <StyledTextField
                label="From Address 2"
                fullWidth
                variant="outlined"
                margin="normal"
                value={fromData.address2}
                onChange={(e) =>
                  setFromData((prev) => ({ ...prev, address2: e.target.value }))
                }
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
              />
              <StyledTextField
                label="From City"
                fullWidth
                variant="outlined"
                margin="normal"
                value={fromData.city}
                onChange={(e) =>
                  setFromData((prev) => ({ ...prev, city: e.target.value }))
                }
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                required
              />
              <StyledTextField
                label="From State"
                fullWidth
                select
                variant="outlined"
                margin="normal"
                value={fromData.state}
                onChange={(e) =>
                  setFromData((prev) => ({ ...prev, state: e.target.value }))
                }
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                required>
                {stateOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                label="From Zip"
                fullWidth
                variant="outlined"
                margin="normal"
                value={fromData.zip}
                onChange={(e) =>
                  handleNumericChange(e, "zip", fromData, setFromData)
                }
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                required
              />
              {bannedZipCodes.includes(fromData.zip) && (
                <div style={{ color: "orange", marginTop: "10px" }}>
                  <span className="warning-icon">⚠️</span>
                  <span>
                    Your recipient's zip code is a high-risk area. Please use
                    the EVS template for the highest success rate.
                  </span>
                </div>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveFromAddress}
                    onChange={(e) => setSaveFromAddress(e.target.checked)}
                  />
                }
                label="Save From Address"
              />
            </div>

            <div
              className="card border border-dashed border-border-color"
              style={{
                width: "100%",
                maxWidth: "450px",
                margin: "0 auto",
                borderRadius: "15px",
                padding: "20px",
                border: "1px dashed gray"
              }}>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                style={{
                  color: "font-color",
                  fontWeight: "bold",
                }}>
                To Address
              </Typography>
              <StyledTextField
                label="Select Recipient"
                fullWidth
                select
                variant="outlined"
                margin="normal"
                onChange={handleToSelect}
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}>
                {toAddresses.map((address) => (
                  <MenuItem key={address.id} value={address.id}>
                    {address.address_name}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                label="To Name"
                fullWidth
                variant="outlined"
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                value={toData.name}
                onChange={(e) =>
                  setToData((prev) => ({ ...prev, name: e.target.value }))
                }
                required
              />
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <StyledTextField
                  label="To Phone"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={toPhone}
                  onChange={(e) => setToPhone(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                    "& label": { color: "gray" },
                    "& input": { color: "#ffffff" },
                  }}
                />
                <IconButton color="primary" onClick={handleGenerateToPhone}>
                  <Loop />
                </IconButton>
              </div>
              <Autocomplete
                onLoad={(autocomplete) => {
                  toAutocompleteRef.current = autocomplete;
                }}
                onPlaceChanged={() => handlePlaceSelect("to")}
                options={{
                  componentRestrictions: { country: "us" },
                  types: ["address"],
                }}>
                <StyledTextField
                  label="To Address"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={toData.address}
                  onChange={(e) =>
                    setToData((prev) => ({
                      ...prev,
                      address: abbreviateAddress(e.target.value),
                    }))
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                    "& label": { color: "gray" },
                    "& input": { color: "#ffffff" },
                  }}
                  required
                />
              </Autocomplete>
              <StyledTextField
                label="To Address 2"
                fullWidth
                variant="outlined"
                margin="normal"
                value={toData.address2}
                onChange={(e) =>
                  setToData((prev) => ({ ...prev, address2: e.target.value }))
                }
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
              />
              <StyledTextField
                label="To City"
                fullWidth
                variant="outlined"
                margin="normal"
                value={toData.city}
                onChange={(e) =>
                  setToData((prev) => ({ ...prev, city: e.target.value }))
                }
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                required
              />
              <StyledTextField
                label="To State"
                fullWidth
                select
                variant="outlined"
                margin="normal"
                value={toData.state}
                onChange={(e) =>
                  setToData((prev) => ({ ...prev, state: e.target.value }))
                }
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                required>
                {stateOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                label="To Zip"
                fullWidth
                variant="outlined"
                margin="normal"
                value={toData.zip}
                onChange={(e) =>
                  handleNumericChange(e, "zip", toData, setToData)
                }
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                required
              />
              {bannedZipCodes.includes(toData.zip) && (
                <div style={{ color: "orange", marginTop: "10px" }}>
                  <span className="warning-icon">⚠️</span>
                  <span>
                    Your recipient's zip code is a high-risk area. Please use
                    the EVS template for the highest success rate.
                  </span>
                </div>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveToAddress}
                    onChange={(e) => setSaveToAddress(e.target.checked)}
                  />
                }
                label="Save To Address"
              />
            </div>

            <div
              className="card border border-dashed border-border-color"
              style={{
                width: "100%",
                maxWidth: "450px",
                margin: "0 auto",
                borderRadius: "15px",
                padding: "20px",
                border: "1px dashed gray"
              }}>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                style={{
                  color: "font-color",
                  fontWeight: "bold",
                }}>
                Package Information
              </Typography>
              <StyledTextField
                label="Length (optional)"
                type="number"
                fullWidth
                variant="outlined"
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                onChange={(e) =>
                  handleNumericChange(e, "length", packageData, setPackageData)
                }
              />
              <StyledTextField
                label="Width (optional)"
                type="number"
                fullWidth
                variant="outlined"
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                onChange={(e) =>
                  handleNumericChange(e, "width", packageData, setPackageData)
                }
              />
              <StyledTextField
                label="Height (optional)"
                type="number"
                fullWidth
                variant="outlined"
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                onChange={(e) =>
                  handleNumericChange(e, "height", packageData, setPackageData)
                }
              />
              <StyledTextField
                label="Weight"
                type="text"
                fullWidth
                variant="outlined"
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  setPackageData((prevData) => ({
                    ...prevData,
                    weight: numericValue,
                  }));
                }}
                value={packageData.weight}
                required
              />
              <ThemeProvider theme={customTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box
                    sx={{
                      maxWidth: "300px",
                      width: "100%",
                      borderRadius: "10px",
                      marginTop: "16px",
                    }}>
                    <DatePicker
                      label="Shipment Date"
                      value={shipmentDate}
                      onChange={(newDate) => setShipmentDate(newDate)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                            "& .MuiFormLabel-root": {
                              color: "gray",
                            },
                            "& input": {
                              color: "#ffffff",
                              "&::placeholder": {
                                color: "gray",
                              },
                            },
                          }}
                          placeholder="YYYY-MM-DD"
                        />
                      )}
                      inputFormat="yyyy-MM-dd"
                      disablePast
                      minDate={
                        new Date(new Date().setDate(new Date().getDate() - 7))
                      }
                      maxDate={
                        new Date(new Date().setDate(new Date().getDate() + 180))
                      }
                    />
                  </Box>
                </LocalizationProvider>
              </ThemeProvider>
              <FormControlLabel
                control={
                  <Switch
                    checked={hazardous}
                    onChange={() => setHazardous((prev) => !prev)}
                    color="error"
                  />
                }
                label="Contains Hazardous Materials"
                style={{ marginTop: "10px", color: "gray" }}
              />
            </div>

            {}
            <div
              className="card border"
              style={{
                width: "100%",
                maxWidth: "450px",
                margin: "0 auto",
                borderRadius: "15px",
                padding: "20px",
                marginTop: "20px",
                border: "1px dashed gray"
              }}>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                style={{
                  color: "font-color",
                  fontWeight: "bold",
                }}>
                Provider & Template
              </Typography>
              <StyledTextField
                label="Provider"
                fullWidth
                select
                variant="outlined"
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                value={serviceCode}
                onChange={handleServiceCodeChange}>
                <MenuItem value="USPS_GROUND_15">USPS Ground 1-15 OZ</MenuItem>
                <MenuItem value="USPS_GROUND_70">USPS Ground 1-70 LB</MenuItem>
                <MenuItem value="USPS_PRIORITY">USPS Priority 1-70 LB</MenuItem>
                <MenuItem value="USPS_EXPRESS">USPS Express 1-70 LB</MenuItem>
              </StyledTextField>
              <StyledTextField
                label="Template"
                fullWidth
                select
                variant="outlined"
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                  "& label": { color: "gray" },
                  "& input": { color: "#ffffff" },
                }}
                value={template}
                onChange={handleTemplateChange}>
                <MenuItem value="SHIPPO">SHIPPO</MenuItem>
                <MenuItem value="EVS">EVS</MenuItem>
                <MenuItem value="EVS_V2">EVS V2</MenuItem>
                <MenuItem value="AMZ">AMAZON</MenuItem>
                <MenuItem value="EASYPOST">EASYPOST</MenuItem>
              </StyledTextField>
              <Typography
                variant="h6"
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                  color: "#4ecca3",
                }}>
                Price: {price ? `$${price}` : "Fetching..."}
              </Typography>
            </div>
          </div>

          <Button
            onClick={() => {
              if (validateRequiredFields()) {
                handleSubmit();
              } else {
                alert("Please fill out all required fields.");
              }
            }}
            variant="contained"
            style={{
              marginTop: "20px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              padding: "12px 24px",
              borderRadius: "8px",
              fontSize: "1.1rem",
              fontWeight: "bold",
              backgroundColor: "#ffffff",
              color: "#000000",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.2s, box-shadow 0.2s",
              pointerEvents:
                isLoading || !validateRequiredFields() ? "none" : "auto",
              opacity: isLoading || !validateRequiredFields() ? 0.6 : 1,
            }}
            disabled={isLoading || !validateRequiredFields()}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#f0f0f0";
              e.currentTarget.style.color = "#333333";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "#ffffff";
              e.currentTarget.style.color = "#000000";
            }}
            onMouseDown={(e) => {
              e.currentTarget.style.transform = "scale(0.98)";
              e.currentTarget.style.boxShadow =
                "0px 2px 6px rgba(0, 0, 0, 0.2)";
            }}
            onMouseUp={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow =
                "0px 4px 10px rgba(0, 0, 0, 0.2)";
            }}>
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Create Label"
            )}
          </Button>

          {notification.message && (
            <Alert severity={notification.type} style={{ marginTop: "20px" }}>
              {notification.message}
            </Alert>
          )}
        </div>
      </LoadScriptNext >
    </>
  );
};

export default LabelCreation;
