import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Modal,
  Tooltip,
  IconButton,
  Alert,
  Paper,
  Button
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faWallet, faKey, faEnvelope, faRedo } from "@fortawesome/free-solid-svg-icons";
import Pagination from "@mui/material/Pagination";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import Breadcrumb from "../../components/common/Breadcrumb";

const PaginationStyled = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "gray",
  },
  "& .Mui-selected": {
    backgroundColor: "#4ecca3",
    color: "#fff",
  },
});

const TableStyled = styled(TableContainer)({
    marginTop: 20,
    borderRadius: 8,
    color: "#ffffff",
    border: "2px dotted var(--border-color)",
    backgroundColor: "var(--card-color)",
  });
  
  const ModalContainer = styled(Box)({
    border: "2px dotted var(--border-color)",
    backgroundColor: "var(--card-color)",
    borderRadius: 10,
    padding: 20,
    color: "#b0b0b0",
    maxWidth: "600px",
    margin: "auto",
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center",
  });

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [notification, setNotification] = useState({ message: "", error: false });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalType, setModalType] = useState("");
  const [filters, setFilters] = useState({ email: "", balance: "", createdAt: "" });

  const usersPerPage = 10;
  const token = localStorage.getItem("token");

  const fetchUsers = async () => {
    try {
      const response = await fetch(`http://localhost:3000/api/admin/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setUsers(data.users || []);
      setFilteredUsers(data.users || []);
      setTotalPages(Math.ceil(data.users.length / usersPerPage));
    } catch (error) {
      setNotification({ message: "Error fetching users", error: true });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const applyFilters = () => {
    let filtered = users;

    if (filters.email) {
      filtered = filtered.filter((user) =>
        user.email.toLowerCase().includes(filters.email.toLowerCase())
      );
    }
    if (filters.balance) {
      filtered = filtered.filter((user) =>
        parseFloat(user.balance) >= parseFloat(filters.balance)
      );
    }
    if (filters.createdAt) {
      filtered = filtered.filter((user) =>
        new Date(user.created_at).toLocaleDateString().includes(filters.createdAt)
      );
    }

    setFilteredUsers(filtered);
    setTotalPages(Math.ceil(filtered.length / usersPerPage));
    setCurrentPage(1);
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  const handleOpenModal = (type, user) => {
    setModalType(type);
    setModalData(user);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData({});
  };

  const handleSave = async () => {
    try {
      let url, body;
      if (modalType === "ban") {
        url = `http://localhost:3000/api/admin/ban-user`;
        body = { user_id: modalData.id, ban_reason: modalData.ban_reason };
      } else if (modalType === "balance") {
        url = `http://localhost:3000/api/admin/update-balance`;
        body = { user_id: modalData.id, balance: modalData.new_balance };
      } else if (modalType === "password") {
        url = `http://localhost:3000/api/admin/update-password`;
        body = { user_id: modalData.id, new_password: modalData.new_password };
      } else if (modalType === "email") {
        url = `http://localhost:3000/api/admin/update-email`;
        body = { user_id: modalData.id, new_email: modalData.new_email };
      } else if (modalType === "reset_balance") {
        url = `http://localhost:3000/api/admin/reset-user-balance`;
        body = { user_id: modalData.id };
      }
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
  
      const data = await response.json();
  
      if (data.success) {
        setNotification({ message: data.message, error: false });
        fetchUsers();
      } else {
        setNotification({ message: data.message || "Action failed", error: false });
        fetchUsers();
      }
    } catch (error) {
      setNotification({ message: "Error performing action", error: true });
    } finally {
      handleCloseModal()
    }
  };
  

  const renderTableRows = () => {
    const startIndex = (currentPage - 1) * usersPerPage;
    const paginatedUsers = filteredUsers.slice(startIndex, startIndex + usersPerPage);

    return paginatedUsers.map((user) => (
      <TableRow key={user.id}>
        <TableCell  style={{ color: "#b0b0b0" }}>{user.id}</TableCell>
        <TableCell  style={{ color: "#b0b0b0" }}>{user.email}</TableCell>
        <TableCell  style={{ color: "#b0b0b0" }}>${parseFloat(user.balance).toFixed(2)}</TableCell>
        <TableCell  style={{ color: "#b0b0b0" }}>{user.role === 1 ? "Admin" : "User"}</TableCell>
        <TableCell  style={{ color: "#b0b0b0" }}>{user.is_banned ? "Yes" : "No"}</TableCell>
        <TableCell  style={{ color: "#b0b0b0" }}>{user.banned_reason || "N/A"}</TableCell>
        <TableCell  style={{ color: "#b0b0b0" }}>{new Date(user.created_at).toLocaleString()}</TableCell>
        <TableCell  style={{ color: "#b0b0b0" }}>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            <Tooltip title="Ban User">
              <IconButton onClick={() => handleOpenModal("ban", user)} color="error">
                <FontAwesomeIcon icon={faBan} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Update Balance">
              <IconButton onClick={() => handleOpenModal("balance", user)} color="success">
                <FontAwesomeIcon icon={faWallet} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset Balance">
              <IconButton onClick={() => handleOpenModal("reset_balance", user)} color="warning">
                <FontAwesomeIcon icon={faRedo} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Update Password">
              <IconButton onClick={() => handleOpenModal("password", user)} color="warning">
                <FontAwesomeIcon icon={faKey} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Update Email">
              <IconButton onClick={() => handleOpenModal("email", user)} color="info">
                <FontAwesomeIcon icon={faEnvelope} />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
   <div style={{ marginTop: "15px", marginLeft: "35px" }}>
   <Breadcrumb breadcrumbItem={[{ name: "Manage" }, { name: "User" }, { name: "Pricing" }]} />
     <WelcomeHeader />
     </div>
    <Box sx={{ padding: 3 }}>
      {notification.message && (
        <Alert
          severity={notification.error ? "error" : "success"}
          sx={{ mb: 2 }}
          onClose={() => setNotification({ message: "", error: false })}
        >
          {notification.message}
        </Alert>
      )}

<Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <TextField
           value={filters.email}
           onChange={(e) => setFilters({ ...filters, email: e.target.value })}
          placeholder="Search by ID, User ID, Track ID, Trans ID, Price..."
          variant="outlined"
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "gray",
                borderStyle: "dotted",
              },
              "&:hover fieldset": {
                borderColor: "lightgray",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#4ecca3",
              },
              color: "gray",
            },
            "& .MuiInputBase-input": {
              color: "gray",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "gray",
            },
          }}
        />
      </Box>


      <TableStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>ID</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Email</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Balance</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Role</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Banned</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Banned Reason</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Created At</TableCell>
              <TableCell style={{ color: "#b0b0b0", fontWeight: "bold" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableStyled>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <PaginationStyled
          count={totalPages}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
        />
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>

  <ModalContainer>
    <Typography variant="h6">
      {modalType === "ban" && "Ban User"}
      {modalType === "balance" && "Update Balance"}
      {modalType === "password" && "Update Password"}
      {modalType === "email" && "Update Email"}
      {modalType === "reset_balance" && "Reset Balance"}
    </Typography>
    {modalType === "ban" && (
      <TextField
        label="Ban Reason"
        value={modalData.ban_reason || ""}
        onChange={(e) => setModalData({ ...modalData, ban_reason: e.target.value })}
        fullWidth
        sx={{
          mt: 2,
          width: "300px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "gray",
              borderStyle: "dotted",
            },
            "&:hover fieldset": {
              borderColor: "lightgray",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#4ecca3",
            },
            color: "gray",
          },
          "& .MuiInputBase-input": {
            color: "gray",
          },
          "& .MuiInputBase-input::placeholder": {
            color: "gray",
          },
        }}
      />
    )}
    {modalType === "balance" && (
      <TextField
        label="New Balance"
        value={modalData.new_balance || ""}
        onChange={(e) => setModalData({ ...modalData, new_balance: e.target.value })}
        fullWidth
        sx={{
            mt: 2,
          width: "300px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "gray",
              borderStyle: "dotted",
            },
            "&:hover fieldset": {
              borderColor: "lightgray",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#4ecca3",
            },
            color: "gray",
          },
          "& .MuiInputBase-input": {
            color: "gray",
          },
          "& .MuiInputBase-input::placeholder": {
            color: "gray",
          },
        }}
      />
    )}
    {modalType === "password" && (
      <TextField
        label="New Password"
        value={modalData.new_password || ""}
        onChange={(e) => setModalData({ ...modalData, new_password: e.target.value })}
        fullWidth
        sx={{ 
            mt: 2,
          width: "300px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "gray",
              borderStyle: "dotted",
            },
            "&:hover fieldset": {
              borderColor: "lightgray",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#4ecca3",
            },
            color: "gray",
          },
          "& .MuiInputBase-input": {
            color: "gray",
          },
          "& .MuiInputBase-input::placeholder": {
            color: "gray",
          },
        }}
      />
    )}
    {modalType === "email" && (
      <TextField
        label="New Email"
        value={modalData.new_email || ""}
        onChange={(e) => setModalData({ ...modalData, new_email: e.target.value })}
        fullWidth
        sx={{
            mt: 2,
          width: "300px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "gray",
              borderStyle: "dotted",
            },
            "&:hover fieldset": {
              borderColor: "lightgray",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#4ecca3",
            },
            color: "gray",
          },
          "& .MuiInputBase-input": {
            color: "gray",
          },
          "& .MuiInputBase-input::placeholder": {
            color: "gray",
          },
        }}
      />
    )}
    <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
      <Button onClick={handleCloseModal} color="error">
        Cancel
      </Button>
      <Button onClick={handleSave} color="primary">
        Save
      </Button>
    </Box>
  </ModalContainer>
</Modal>

    </Box>
    </>
  );
};

export default ManageUsers;
