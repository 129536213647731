import Routers from "./routers/Routers";
import "./assets/css/main.css"
import "./assets/css/font.css"
import 'react-quill/dist/quill.snow.css';
import 'lightbox.js-react/dist/index.css'

export default function App() {
  return (
    <Routers />
  );
}