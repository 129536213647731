import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IconPlus } from "@tabler/icons-react";
import {
  IconArrowsMaximize,
  IconMoonStars,
  IconSettings,
  IconBrush,
  IconNote,
  IconCreditCard,
  IconArrowBigLeftFilled,
  IconBrandDiscord,
} from "@tabler/icons-react";
import {
  font_mali,
  font_quicksand,
  font_mulish,
  font_jura,
  profile_av,
  sidebarImg1,
  sidebarImg5,
  sidebarImg4,
  sidebarImg3,
  sidebarImg2,
} from "../../assets/images";

import jwtDecode from "jwt-decode";

const getUserEmail = () => {
  const token = localStorage.getItem("token");
  if (!token) return null;
  try {
    const decoded = jwtDecode(token);
    return decoded?.email || null;
  } catch (err) {
    console.error("Error decoding JWT token:", err);
    return null;
  }
};

const emailofuser = getUserEmail();
const username = emailofuser ? emailofuser.split("@")[0] : "User";

export default function Header({
  toggleMobileNav,
  mobileNav,
  toggleNote,
  toggleChat,
  containerToggle,
  container,
}) {
  const [miniSidebar, setMiniSidebar] = useState(false);
  const toggleMiniSidebar = () => {
    setMiniSidebar((prev) => !prev);
  };
  const navigate = useNavigate();
  const [balance, setBalance] = useState(null);
  useEffect(() => {
    const sidebarElement = document.querySelector(".admin-wrapper");
    if (sidebarElement) {
      if (miniSidebar) {
        sidebarElement.classList.add("mini-sidebar");
      } else {
        sidebarElement.classList.remove("mini-sidebar");
      }
    }
  }, [miniSidebar]);

  // full screen
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("http://localhost:3000/api/balance", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setBalance(data?.balance || "0.00");
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    };

    fetchBalance();

    const interval = setInterval(fetchBalance, 10000);

    return () => clearInterval(interval);
  }, []);


  const [themeSetting, setThemeSetting] = useState(false);
  const toggleThemeSetting = () => {
    setThemeSetting(!themeSetting);
    document.body.classList.toggle("overflow-hidden", !themeSetting);
  };

  // color setting
  const [selectedTheme, setSelectedTheme] = useState("indigo");
  const handleThemeChange = (name) => {
    setSelectedTheme(name);
    document.body.setAttribute("data-luno-theme", name);
  };
  useEffect(() => {
    document.body.setAttribute("data-luno-theme", selectedTheme);
  }, [selectedTheme]);

  // dynamic color setting
  const handleChangeDynamicColor = (newColor, index) => {
    const updatedDynamicColorItem = [...dynamicColorItem];
    updatedDynamicColorItem[index].colorValue = newColor.rgb;
    setDynamicColorItem(updatedDynamicColorItem);
    updateCssVariable(updatedDynamicColorItem[index].variable, newColor.rgb);
  };
  const handleClickDynamicColor = (index) => {
    const updatedDynamicColorItem = [...dynamicColorItem];
    updatedDynamicColorItem[index].displayColorPicker =
      !updatedDynamicColorItem[index].displayColorPicker;
    setDynamicColorItem(updatedDynamicColorItem);
  };
  const handleCloseDynamicColor = (index) => {
    const updatedDynamicColorItem = [...dynamicColorItem];
    updatedDynamicColorItem[index].displayColorPicker = false;
    setDynamicColorItem(updatedDynamicColorItem);
  };
  const updateCssVariable = (variable, color) => {
    document.documentElement.style.setProperty(
      variable,
      `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
    );
  };

  const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    document.documentElement.setAttribute(
      "data-theme",
      newDarkMode ? "dark" : "light"
    );
  };
  useEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      darkMode ? "dark" : "light"
    );
  }, [darkMode]);

  // rtl mode
  const [rtlMode, setRtlMode] = useState(false);
  const toggleRtlMode = () => {
    const newRtlMode = !rtlMode;
    setRtlMode(newRtlMode);
    document.documentElement.setAttribute("dir", newRtlMode ? "rtl" : "ltr");
  };
  useEffect(() => {
    document.documentElement.setAttribute("dir", rtlMode ? "rtl" : "ltr");
  }, [rtlMode]);

  // font family setting
  const [selectedFontFamily, setSelectedFontFamily] =
    useState("Mulish, sans-serif");
  const toggleFontFamily = (fontFamily) => {
    setSelectedFontFamily(fontFamily);
    document.body.style.setProperty("--font-family", fontFamily);
  };

  // dynamic font setting
  const [fontLink, setFontLink] = useState("");
  const [fontUrl, setFontUrl] = useState("");
  const handleApply = () => {
    const link = document.createElement("link");
    link.href = fontUrl;
    link.rel = "stylesheet";
    document.head.appendChild(link);
    document.body.style.fontFamily = fontLink;
  };
  const handleClear = () => {
    const link = document.querySelector(`link[href="${fontUrl}"]`);
    if (link) {
      link.remove();
    }
    document.body.style.fontFamily = "";
    setFontLink("");
    setFontUrl("");
  };

  // page header setting
  const [headerFix, setHeaderFix] = useState(true);
  const headerFixToggle = () => {
    setHeaderFix(!headerFix);
  };

  // border radius setting
  const [showRadius, setShowRadius] = useState(true);
  const radiusToggle = () => {
    setShowRadius(!showRadius);
    document.body.classList.toggle("radius-0");
  };

  // sidebar background image setting
  const [sidebarBg, setSidebarBg] = useState(false);
  const [activeBgImage, setActiveBgImage] = useState(sidebarImg1);
  const sidebarBgItem = [
    {
      image: sidebarImg1,
    },
    {
      image: sidebarImg2,
    },
    {
      image: sidebarImg3,
    },
    {
      image: sidebarImg4,
    },
    {
      image: sidebarImg5,
    },
  ];
  const sidebarBgToggle = () => {
    setSidebarBg(!sidebarBg);
    const sidebar = document.getElementsByClassName("sidebar")[0];
    if (sidebar) {
      sidebar.classList.toggle("sidebar-image");
      // Set the CSS variable for the sidebar background
      document.documentElement.style.setProperty(
        "--sidebar-bg-image",
        `url('${activeBgImage}')`
      );
    }
  };
  const handleImageClick = (image) => {
    setActiveBgImage(image); // Set the clicked image as the active one
    const sidebar = document.getElementsByClassName("sidebar")[0];
    if (sidebar) {
      document.documentElement.style.setProperty(
        "--sidebar-bg-image",
        `url('${image}')`
      );
    }
  };

  // box shadow setting
  const [showShadow, setShowShadow] = useState(false);
  const cardShadow = document.querySelectorAll(".card");
  const shadowToggle = () => {
    setShowShadow(!showShadow);
    cardShadow.forEach((card) => {
      card.classList.toggle("shadow-shadow-sm");
    });
  };

  // search bar open
  const [searchBar, setSearchBar] = useState(false);
  const searchRef = useRef(null);
  const inputRef = useRef(null);
  const toggleSearchBar = () => {
    setSearchBar(true);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setSearchBar(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [searchBar]);

  const colorItem = [
    {
      name: "indigo",
      color: "bg-theme-indigo",
    },
    {
      name: "blue",
      color: "bg-theme-blue",
    },
    {
      name: "cyan",
      color: "bg-theme-cyan",
    },
    {
      name: "green",
      color: "bg-theme-green",
    },
    {
      name: "orange",
      color: "bg-theme-orange",
    },
    {
      name: "blush",
      color: "bg-theme-blush",
    },
    {
      name: "red",
      color: "bg-theme-red",
    },
    {
      name: "dynamic",
      color: "bg-primary-10",
      icon: IconBrush,
    },
  ];

  const [dynamicColorItem, setDynamicColorItem] = useState([
    {
      color: "bg-primary",
      label: "Primary Color",
      variable: "--primary",
    },
    {
      color: "bg-secondary",
      label: "Secondary Color",
      variable: "--secondary",
    },
    {
      color: "bg-body-color",
      label: "Body Background",
      variable: "--body-color",
    },
    {
      color: "bg-card-color",
      label: "Card Background",
      variable: "--card-color",
    },
    {
      color: "bg-border-color",
      label: "Border Color",
      variable: "--border-color",
    },
    {
      color: "bg-chart-color1",
      label: "Chart Color 1",
      variable: "--chart-color1",
    },
    {
      color: "bg-chart-color2",
      label: "Chart Color 2",
      variable: "--chart-color2",
    },
    {
      color: "bg-chart-color3",
      label: "Chart Color 3",
      variable: "--chart-color3",
    },
    {
      color: "bg-chart-color4",
      label: "Chart Color 4",
      variable: "--chart-color4",
    },
    {
      color: "bg-chart-color5",
      label: "Chart Color 5",
      variable: "--chart-color5",
    },
  ]);

  const fontItem = [
    {
      image: font_mali,
      font: "Mali, sans-serif",
    },
    {
      image: font_quicksand,
      font: "Quicksand, sans-serif",
    },
    {
      image: font_mulish,
      font: "Mulish, sans-serif",
    },
    {
      image: font_jura,
      font: "Jura, sans-serif",
    },
  ];

  return (
    <>
      <div
        className={`md:py-4 md:px-6 sm:p-3 py-3 border-b-4 border-card-color bg-body-color ${
          headerFix ? "sticky top-0 z-[2] xl:shadow-none shadow-lg" : ""
        }`}>
        <div className="container-fluid flex items-center">
          <div className="flex items-center gap-3 sm:pe-4 pe-2">
            <button
              onClick={toggleMiniSidebar}
              className="xl:flex hidden items-center justify-center w-[36px] h-[36px] min-w-[36px] text-primary bg-primary-10 rounded-full">
              <IconArrowBigLeftFilled
                className={`transition-all ${
                  miniSidebar
                    ? "rotate-180 rtl:rotate-0"
                    : "rotate-0 rtl:rotate-180"
                }`}
              />
            </button>
          </div>

          <div className="flex items-center ms-auto">
            <div
              className="sidebar-bottom-link flex justify-evenly items-center gap-2 mx-2 border border-dashed rounded-full p-[6px] bg-card-color"
              style={{ height: "26px" }}>
              <span className="text-sm font-bold text-font-color">${balance}</span>
              <button
                onClick={() => navigate("/deposit")}
                className="transition-all duration-300 hover:text-secondary flex items-center justify-center w-[22px] h-[22px] rounded-full bg-primary text-white">
                <IconPlus className="w-[14px] h-[14px]" />
              </button>
            </div>

            <button
              onClick={toggleFullScreen}
              className="xl:block hidden md:py-2 md:px-3 p-2 hover:bg-primary-10 transition-all duration-300">
              <IconArrowsMaximize className="stroke-[1.5]" />
            </button>
            <button
              onClick={toggleNote}
              className="xl:hidden sm:block hidden md:py-2 md:px-3 p-2 hover:bg-primary-10 transition-all duration-300">
              <IconNote className="stroke-[1.5] w-[20px] h-[20px]" />
            </button>
            <button
              onClick={toggleDarkMode}
              className="md:py-2 md:px-3 p-2 hover:bg-primary-10 transition-all duration-300">
              <IconMoonStars className="stroke-[1.5] xl:w-[24px] xl:h-[24px] w-[20px] h-[20px]" />
            </button>
            <div className="relative group flex">
              <button className="md:px-3 px-2">
                <img
                  src={profile_av}
                  alt="profile"
                  width="36"
                  height="36"
                  className="w-[36px] h-[36px] min-w-[36px] bg-white shadow-shadow-lg p-1 rounded-full saturate-50 transition-all hover:filter-none"
                />
              </button>
              <div className="bg-card-color text-font-color rounded-xl overflow-hidden md:w-[240px] w-[calc(100%-30px)] shadow-shadow-lg md:absolute fixed md:end-0 end-15 md:top-full top-[55px] origin-top-right rtl:origin-top-left z-[1] opacity-0 invisible scale-0 transition-all duration-300 group-hover:opacity-100 group-hover:visible group-hover:scale-100">
                <div className="p-4 border-b border-border-color">
                  <div className="font-semibold">{username}</div>
                  <div className="text-font-color-100">{emailofuser}</div>
                </div>
                <div className="p-1 m-1 custom-scrollbar overflow-auto max-h-[calc(80svh-163px)]">
                  <Link
                    to="settings"
                    className="py-2 px-4 flex items-center gap-3">
                    <IconSettings className="w-[16px] h-[16px]" />
                    Settings
                  </Link>
                  <Link
                    to="deposit"
                    className="py-2 px-4 flex items-center gap-3">
                    <IconCreditCard className="w-[16px] h-[16px]" />
                    Deposit
                  </Link>
                  <a
                    href="https://discord.gg/hiddenlabs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="py-2 px-4 flex items-center gap-3">
                    <IconBrandDiscord className="w-[16px] h-[16px]" />
                    Discord
                  </a>
                </div>
                <Link
                  to="logout"
                  className="primary-10 uppercase text-[14px]/[20px] text-white py-5 px-10 text-center w-full inline-block">
                  Sign Out
                </Link>
              </div>
            </div>
            <button
              className={`md:py-2 md:px-3 p-2 hover:bg-primary-10 transition-all duration-300 xl:hidden hamburger-menu ${
                mobileNav ? "opened" : ""
              }`}
              onClick={toggleMobileNav}>
              <svg width="20" height="20" viewBox="0 0 100 100">
                <path
                  className="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                />
                <path className="line line2" d="M 20,50 H 80" />
                <path
                  className="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
