import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Breadcrumb from "../../components/common/Breadcrumb";
import WelcomeHeader from "../../components/common/WelcomeHeader";
import { Box, Typography, Skeleton, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  IconShoppingCart,
  IconBox,
  IconAccessPointOff,
  IconChartLine,
  IconShoppingBag,
  IconCoin,
  IconArrowDownCircle,
  IconArrowUpCircle,
} from "@tabler/icons-react";

const StatBox = styled(Box)(({ theme }) => ({
  backgroundColor: "var(--card-color)",
  color: "var(--text-color)",
  borderRadius: "10px",
  padding: "20px",
  textAlign: "center",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 12px rgba(0, 0, 0, 0.2)",
  },
}));

const StatValue = styled(Typography)({
  fontSize: "1.8rem",
  fontWeight: "bold",
  margin: "10px 0",
  color: "var(--text-color)",
});

const StatLabel = styled(Typography)({
  fontSize: "1rem",
  fontWeight: "normal",
  color: "var(--text-secondary-color)",
});

const StatIcon = styled(Box)(({ color }) => ({
  fontSize: "2.5rem",
  color: color,
  marginBottom: "10px",
}));

const StatProgress = styled(Box)(({ barColor }) => ({
  height: "8px",
  backgroundColor: "var(--border-color)",
  borderRadius: "5px",
  overflow: "hidden",
  marginTop: "10px",
  "& div": {
    height: "100%",
    backgroundColor: barColor,
    transition: "width 0.5s ease",
  },
}));

export default function Index() {
  const { userData } = useOutletContext();
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  const breadcrumbItem = [
    {
      name: "Dashboard",
    },
  ];

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("http://localhost:3000/api/user-stats", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error("Error fetching user stats:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStats();
  }, []);

  const icons = {
    totalOrders: { icon: <IconShoppingCart />, color: "#4caf50" },
    totalBulkOrders: { icon: <IconBox />, color: "#ff9800" },
    totalDeposits: { icon: <IconAccessPointOff />, color: "#3f51b5" },
    totalSpentOnOrders: { icon: <IconShoppingBag />, color: "#e91e63" },
    totalSpentOnBulkOrders: { icon: <IconCoin />, color: "#9c27b0" },
    totalSpent: { icon: <IconChartLine />, color: "#009688" },
    averageSpent: { icon: <IconArrowUpCircle />, color: "#2196f3" },
    averageDeposit: { icon: <IconArrowDownCircle />, color: "#f44336" },
  };

  const progressColors = {
    totalOrders: "#4caf50",
    totalBulkOrders: "#ff9800",
    totalDeposits: "#3f51b5",
    totalSpentOnOrders: "#e91e63",
    totalSpentOnBulkOrders: "#9c27b0",
    totalSpent: "#009688",
    averageSpent: "#2196f3",
    averageDeposit: "#f44336",
  };

  const formatValue = (key, value) => {
    const priceKeys = [
      "totalSpentOnOrders",
      "totalSpentOnBulkOrders",
      "totalSpent",
      "averageSpent",
      "averageDeposit",
    ];
    return priceKeys.includes(key) ? `$${value}` : value;
  };

  const renderStatBox = (label, value, progress, icon, color, barColor) => (
    <StatBox>
      <StatIcon color={color}>{icon}</StatIcon>
      <StatValue>{value}</StatValue>
      <StatLabel>{label}</StatLabel>
      <StatProgress barColor={barColor}>
        <div style={{ width: `${Math.min(progress, 100)}%` }} />
      </StatProgress>
    </StatBox>
  );

  return (
    <div className="md:px-6 sm:px-3 pt-4">
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={breadcrumbItem} />
        <WelcomeHeader />
        {loading ? (
          <Grid container spacing={2}>
            {Array.from({ length: 8 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Skeleton variant="rectangular" height={150} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={3}>
            {Object.keys(icons).map((key, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                {renderStatBox(
                  key.replace(/([A-Z])/g, " $1"),
                  formatValue(key, stats[key] || 0),
                  (stats[key] / 100) * 100,
                  icons[key].icon,
                  icons[key].color,
                  progressColors[key]
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
}
